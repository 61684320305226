import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Modal,
  Box,
  TextField,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";

import ChecklistTable from "../../components/ChecklistTable/ChecklistTable";
import AuthContext from "../../store/auth-context";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { showError, showWarning, showInfo } from "../../utils/notifications";
import c172NormalTemplate from "../../checklistTemplates/c172_normal.json";
import c172EmergencyTemplate from "../../checklistTemplates/c172_emergency.json";
import emptyTemplate from "../../checklistTemplates/empty.json";
import { modalStyle } from "../../utils/modalStyle";
import FeedbackBox from "../../components/FeedbackBox/FeedbackBox";
import HowDidYouHearAboutUsPopup from "../../components/HowDidYouHearAboutUsPopup/HowDidYouHearAboutUsPopup";
import classes from "./AllChecklists.module.css";

const AllChecklists = () => {
  const [checklists, setChecklists] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [alert, setAlert] = useState(false); // Used to trigger re-render of checklists in useEffect
  const [subscriptionLimits, setSubscriptionLimits] = useState({
    maxChecklists: 0,
    maxCheckItems: 0,
  });
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(emptyTemplate);
  const [checklistIsCreating, setChecklistIsCreating] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const triggerRender = () => {
    setAlert(true);
  };

  const handleChangeTemplate = (event) => {
    setTemplate(event.target.value);
    setName(event.target.value.name);
  };

  const handleCreateNewChecklist = async (event) => {
    event.preventDefault();

    try {
      // If name is empty dont allow submission.
      if (name === "") {
        showError("Error", "Checklist name cannot be empty");
        return;
      }

      // If user isnt admin, check their subscription limits.
      if (!authCtx.isAdmin) {
        // Check that plan is active and limits arent exceeded.
        if (
          checklists.length >= subscriptionLimits.maxChecklists ||
          !isSubscriptionActive
        ) {
          showInfo(
            "Sorry!",
            "You have reached your max number of checklists. Upgrade your subscription to get more."
          );
          setShowModal(false);
          return;
        }
      }
      const session = await Auth.currentSession();
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists`;
      let body = {
        ...template,
        name,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      // Send request
      setChecklistIsCreating(true);
      axios
        .post(url, body, config)
        .then((res) => {
          setChecklistIsCreating(false);
          setShowModal(false);
          setName("");
          // Redirect to new checklist editor page.
          if ("id" in res.data) {
            navigate(`/checklists/${res.data.id}`);
          } else {
            triggerRender();
          }
        })
        .catch((err) => {
          setChecklistIsCreating(false);
          handleAxiosError(err);
        });
    } catch (err) {
      setChecklistIsCreating(false);
      console.error(err);
      handleAuthError(err, navigate, authCtx);
    }
  };

  // Fetch checklists from api
  useEffect(() => {
    const loadChecklists = async () => {
      try {
        setIsLoading(true);
        setAlert(false); // Reset the alert state
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists`;
        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        // Get checklists
        axios
          .get(url, config)
          .then((res) => {
            setChecklists(res.data.Items);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });

        // Get subscriptionLimits
        url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/subscriptions/info`;
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.error) {
              showWarning("Warning", res.data.error);
            } else {
              setSubscriptionLimits(res.data.limits);
              setIsSubscriptionActive(res.data.active);
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      } catch (err) {
        console.error(err);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadChecklists();
  }, [alert, authCtx, navigate]);

  const loadingSpinner = (
    <div>
      <ScaleLoader color="#4f4fee" />
    </div>
  );
  const checklistTable = (
    <ErrorBoundary>
      <ChecklistTable
        checklists={checklists}
        triggerRender={triggerRender}
        limits={subscriptionLimits}
        isSubscriptionActive={isSubscriptionActive}
      />
    </ErrorBoundary>
  );

  const displayContent = isLoading ? loadingSpinner : checklistTable;

  return (
    <div className={classes.container}>
      <h1>Checklists</h1>
      {displayContent}
      <Button
        variant="contained"
        color="neutral"
        onClick={handleOpenModal}
        sx={{ marginTop: "10px", marginBottom: "10px;" }}
      >
        New Checklist
      </Button>
      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <h3>Create New Checklist</h3>
          <form>
            <FormControl fullWidth size="small">
              <InputLabel id="template-label">Template</InputLabel>
              <Select
                fullWidth
                labelId="template-label"
                name="template"
                label="Template"
                value={template}
                defaultValue={emptyTemplate}
                onChange={handleChangeTemplate}
              >
                <MenuItem value={emptyTemplate}>None</MenuItem>
                <MenuItem value={c172NormalTemplate}>
                  Cessna 172 (Normal)
                </MenuItem>
                <MenuItem value={c172EmergencyTemplate}>
                  Cessna 172 (Emergency)
                </MenuItem>
              </Select>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                id="name-input"
                label="Name"
                variant="outlined"
                onChange={handleNameChange}
                value={name}
              />
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  color="neutral"
                  size="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="neutral"
                  size="medium"
                  onClick={handleCreateNewChecklist}
                  loading={checklistIsCreating}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </FormControl>
          </form>
        </Box>
      </Modal>
      <FeedbackBox />
      <HowDidYouHearAboutUsPopup />
    </div>
  );
};

export default AllChecklists;
