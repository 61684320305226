import { useState, memo } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";

import InputField from "../../InputField/InputField";
import classes from "./FrequencyItem.module.css";

const FrequencyItem = memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  /**
   * Handles the onchange event for the name input of the FrequencyItem.
   * @param {object} event
   */
  const handleNameUpdate = (event) => {
    props.onNameUpdate(props.idOfFrequencyBox, props.id, event.target.value);
  };

  /**
   * Handles the onchange event for the frequency input of the FrequencyItem.
   * @param {object} event
   */
  const handleFrequencyUpdate = (event) => {
    props.onFrequencyUpdate(
      props.idOfFrequencyBox,
      props.id,
      event.target.value
    );
  };

  // Handles the onClick event to delete the current FrequencyItem from the checklist state in 'ChecklistDetail.js'
  const handleDeleteItem = () => {
    props.onDeleteItem(props.idOfFrequencyBox, props.id);
  };

  const handleToggleBold = () => {
    props.onToggleBold(props.idOfFrequencyBox, props.id);
  };

  const handleToggleItalic = () => {
    props.onToggleItalic(props.idOfFrequencyBox, props.id);
  };

  // Handles the mousedown event on the FrequencyItem drag handle.
  const handleMouseDown = (event) => {
    event.stopPropagation();
    setIsDraggable(true);
  };

  // Handles the ondrag event for the FrequencyItem.
  const handleDrag = (event) => {
    event.stopPropagation();
    props.onDrag(props.id);
  };

  // Handles the dragleave event for the FrequencyItem.
  const handleDragLeave = (event) => {
    if (isDraggedOver) {
      event.stopPropagation();
      setIsDraggedOver(false);
    }
  };

  // Handles the dragover event for the FrequencyItem (when another FrequencyItem is dragged over this FrequencyItem).
  const handleDragOver = (event) => {
    event.stopPropagation();
    if (!isDraggedOver) {
      props.onDragOver(props.id);
      setIsDraggedOver(true);
    }
  };

  // Handles the dragend event when the user stops dragging the checkItem.
  const handleDragEnd = (event) => {
    if (isDraggable) {
      event.stopPropagation();
      setIsDraggable(false);
      props.onDragEnd();
      props.resetDraggedId();
    }
  };

  // Handles the mouseup event on the checkItem drag handle.
  const handleMouseUp = (event) => {
    setIsDraggable(false);
    event.stopPropagation();
  };

  return (
    <div
      id={props.id}
      className={
        isDraggedOver ? classes.frequencyItemDraggedOver : classes.frequencyItem
      }
      onDrag={handleDrag}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      draggable={isDraggable}
    >
      <div className={classes.editor}>
        <span
          className={classes.handle}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <DragHandleIcon />
        </span>

        <div className={classes.controls}>
          <span className={classes.frequencyItemForm}>
            <InputField
              label="Name"
              value={props.name}
              onChange={handleNameUpdate}
              minlength="1"
              maxlength="50"
            />
          </span>
          <span className={classes.frequencyItemForm}>
            <InputField
              label="Frequency"
              value={props.frequency}
              onChange={handleFrequencyUpdate}
              minlength="1"
              maxlength="50"
            />
          </span>
          <ToggleButtonGroup>
            <ToggleButton
              value="bold"
              aria-label="bold"
              size="small"
              selected={props.isBold}
              onClick={handleToggleBold}
            >
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              size="small"
              selected={props.isItalic}
              onClick={handleToggleItalic}
            >
              <FormatItalicIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className={classes.deleteButton}>
        <Button
          variant="contained"
          color="danger"
          size="small"
          onClick={handleDeleteItem}
        >
          Delete
        </Button>
      </div>
    </div>
  );
});

export default FrequencyItem;
