import { useParams } from "react-router-dom";

import Checklist from "../../components/Checklist/Checklist";

const ChecklistDetail = () => {
  const { checklistId } = useParams();

  return <Checklist id={checklistId} />;
};

export default ChecklistDetail;
