import { useState, Fragment } from "react";
import Fab from "@mui/material/Fab";

import classes from "./FeedbackBox.module.css";

import FeedbackBoxPopup from "./FeedbackBoxPopup/FeedbackBoxPopup";

const FeedbackBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  let fabStyle = {
    position: "absolute",
    bottom: 16,
    right: 16,
    color: "common.white",
    bgcolor: "#4f4fee",
    "&:hover": {
      bgcolor: "#5f5fee",
    },
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Fragment>
      <Fab
        sx={fabStyle}
        aria-label="feature request"
        color="primary"
        variant="extended"
        onClick={handleOpen}
      >
        <h2 className={classes.emoji}>😃</h2>
        <span className={classes.buttonText}>Feedback?</span>
      </Fab>
      <FeedbackBoxPopup open={isOpen} onClose={handleClose} />
    </Fragment>
  );
};

export default FeedbackBox;
