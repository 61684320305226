import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Modal, Box, Button, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";

import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import AuthContext from "../../store/auth-context";
import { showInfo, showSuccess } from "../../utils/notifications";
import { modalStyle } from "../../utils/modalStyle";
import classes from "./ChecklistTable.module.css";

const ChecklistTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [idOfChecklistToDelete, setIdOfChecklistToDelete] = useState("");
  const [nameOfChecklistToDelete, setNameOfChecklistToDelete] = useState("");
  const [idOfChecklistToCopy, setIdOfChecklistToCopy] = useState("");
  const [nameOfChecklistToCopy, setNameOfChecklistToCopy] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  let checklistTable;
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const handleClickDelete = (id, name) => {
    setIdOfChecklistToDelete(id);
    setNameOfChecklistToDelete(name);
    setShowModal(true);
  };

  const handleClickCopy = (id, name) => {
    setIdOfChecklistToCopy(id);
    setNameOfChecklistToCopy(name);
    setShowCopyModal(true);
  };

  const handleClickCancel = () => {
    setShowModal(false);
    setIdOfChecklistToDelete("");
    setNameOfChecklistToDelete("");
  };

  const handleClickCancelCopy = () => {
    setShowCopyModal(false);
    setIdOfChecklistToCopy("");
    setNameOfChecklistToCopy("");
  };

  const handleCreateChecklistCopy = async () => {
    // This case shouldnt happen, because the id is set when the delete button is pressed.
    if (idOfChecklistToCopy === "") {
      console.log("No checklist to copy");
      return;
    } else if (!showCopyModal) {
      console.log("Cannot copy checklist, modal not open");
      return;
    }

    // If user isnt admin, check their subscription limits.
    if (!authCtx.isAdmin) {
      if (props.checklists.length >= props.limits.maxChecklists) {
        showInfo(
          "Sorry!",
          "You have reached your max number of checklists. Upgrade your subscription to get more."
        );
        setShowModal(false);
        return;
      }
    }

    const id = idOfChecklistToCopy;
    try {
      setIsCopying(true);
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${id}/copy`;
      const session = await Auth.currentSession();
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      axios
        .post(url, {}, config)
        .then((res) => {
          setIsCopying(false);
          setShowCopyModal(false);
          setIdOfChecklistToCopy("");
          setNameOfChecklistToCopy("");
          props.triggerRender();
          showSuccess("Success!", "Checklist copied successfully.");
        })
        .catch((err) => {
          setIsCopying(false);
          setShowCopyModal(false);
          setIdOfChecklistToCopy("");
          setNameOfChecklistToCopy("");
          handleAxiosError(err);
        });
    } catch (err) {
      setIsCopying(false);
      handleAuthError(err, navigate, authCtx);
    }
  };

  const handleDeleteChecklist = async () => {
    // This case shouldnt happen, because the id is set when the delete button is pressed.
    if (idOfChecklistToDelete === "") {
      console.log("No checklist to delete");
      return;
    } else if (!showModal) {
      console.log("Cannot delete checklist, modal not open");
      return;
    }
    const id = idOfChecklistToDelete;

    try {
      setIsDeleting(true);
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${id}`;
      const session = await Auth.currentSession();
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      axios
        .delete(url, config)
        .then((res) => {
          setIsDeleting(false);
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          props.triggerRender();
        })
        .catch((err) => {
          setIsDeleting(false);
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          handleAxiosError(err);
        });
    } catch (err) {
      setIsDeleting(false);
      handleAuthError(err, navigate, authCtx);
    }
  };

  const handleClickOrder = (checklist_id) => {
    navigate(`/order/${checklist_id}`);
  };

  const showSubscriptionError = () => {
    const errMessage = props.isSubscriptionActive
      ? "This checklist is not part of your subscription!"
      : "You need a subscription to edit a checklist.";
    showInfo("Sorry!", errMessage);
  };

  if (props.checklists.length > 0) {
    const sortedChecklists = props.checklists.sort((a, b) => {
      const createdDate_a = new Date(a.createdDate).getTime() / 1000;
      const createdDate_b = new Date(b.createdDate).getTime() / 1000;
      return createdDate_a - createdDate_b;
    });

    const maxChecklists = props.limits.maxChecklists;
    const isActive = props.isSubscriptionActive;

    const checklists = sortedChecklists.map((checklist, index) => (
      <tr key={checklist.id}>
        <td className={classes.tableData}>
          {(index + 1 <= maxChecklists && isActive) || authCtx.isAdmin ? (
            <NavLink to={`/checklists/${checklist.id}`}>
              {checklist.name}
            </NavLink>
          ) : (
            <NavLink to="/checklists" onClick={showSubscriptionError}>
              {checklist.name}
            </NavLink>
          )}
        </td>
        <td className={classes.tableData}>
          {getShortDateString(checklist.createdDate)}
        </td>
        <td className={classes.tableData}>
          {getShortDateString(checklist.lastModifiedDate)}
        </td>
        <td className={classes.tableData}>
          <Button
            variant="contained"
            color="neutral"
            size="small"
            onClick={() => {
              handleClickOrder(checklist.id);
            }}
          >
            Order
          </Button>
          <IconButton
            variant="contained"
            color="neutral"
            size="small"
            onClick={() => {
              handleClickCopy(checklist.id, checklist.name);
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
          <IconButton
            variant="contained"
            color="danger"
            size="small"
            onClick={() => {
              handleClickDelete(checklist.id, checklist.name);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </td>
      </tr>
    ));

    // Delete Checklist Modal
    const deleteChecklistModal = (
      <Modal open={showModal}>
        <Box sx={modalStyle}>
          <h3>Delete Checklist?</h3>
          <p>
            <span className={classes.nameLabel}>Name:</span>{" "}
            <span className={classes.checklistName}>
              {nameOfChecklistToDelete}
            </span>
          </p>
          <Button
            variant="outlined"
            size="medium"
            sx={{ marginRight: "10px" }}
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleDeleteChecklist}
            loading={isDeleting}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Modal>
    );

    // Copy Checklist Modal
    const copyChecklistModal = (
      <Modal open={showCopyModal}>
        <Box sx={modalStyle}>
          <h3>Create a copy of this checklist?</h3>
          <p>
            <span className={classes.nameLabel}>Name:</span>{" "}
            <span className={classes.checklistName}>
              {nameOfChecklistToCopy}
            </span>
          </p>
          <Button
            variant="outlined"
            size="medium"
            sx={{ marginRight: "10px" }}
            onClick={handleClickCancelCopy}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            onClick={handleCreateChecklistCopy}
            loading={isCopying}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Modal>
    );

    checklistTable = (
      <Fragment>
        <table className={classes.table}>
          <thead className={classes.tableHeader}>
            <tr>
              <td className={classes.tableData}>Name</td>
              <td className={classes.tableData}>Created</td>
              <td className={classes.tableData}>Last Modified</td>
              <td className={classes.tableData}>Actions</td>
            </tr>
          </thead>
          <tbody>{checklists}</tbody>
        </table>
        {deleteChecklistModal}
        {copyChecklistModal}
      </Fragment>
    );
  } else {
    checklistTable = (
      <div>
        <p>There are no checklists to display. Create one to get started.</p>
      </div>
    );
  }

  return checklistTable;
};

export default ChecklistTable;
