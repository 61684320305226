import { useRef, useEffect, useState, memo } from "react";

import classes from "./SubchecklistTitle.module.css";

const SubchecklistTitle = memo((props) => {
  const [width, setWidth] = useState(0);
  const spanRef = useRef();

  const minlength = props.minlength;
  const maxlength = props.maxlength;

  // Handles the onChange event for the text input. This updates the value in the checklist state in ChecklistDetail.js
  const handleChangeTitle = (event) => {
    props.onTitleChange(props.subchecklistId, event.target.value);
  };

  // Handles the keydown event.
  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "Escape") {
      event.target.blur();
    }
  };

  // Whenever the title changes, we set the width of the input to the width of the span
  useEffect(() => {
    setWidth(spanRef.current.offsetWidth + 3); // +3 is to stop input text from moving side to side when typing because width is slightly to small
  }, [props.title]);

  let error = false;
  let helperText = "";
  if (props.title.length < minlength) {
    helperText = `Must be at least ${minlength} character.`;
    error = true;
  } else if (props.title.length > maxlength) {
    helperText = `Must be less than ${maxlength} characters.`;
    error = true;
  }

  const classname = error ? classes.errorTitle : classes.title;

  // There is no perfect way to create a dynamic width text input so the method used here has a hidden span element
  // which holds the same content as the SubchecklistTitle text input. Since span elements are resized dynamically based on their
  // contents, we can put the same content from the input into the span and check what the spans width is, and set
  // that width on the text input. This allows the text input to dynamically change its width based on the content.
  return (
    <div className={classname}>
      <span ref={spanRef}>{props.title}</span>
      <input
        type="text"
        style={{ width }}
        onChange={handleChangeTitle}
        value={props.title}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={handleKeyDown}
        placeholder="Title Here..."
      />
      {error && <p className={classes.helperText}>{helperText}</p>}
    </div>
  );
});

export default SubchecklistTitle;
