import { useState, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Modal,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

import ChecklistTitle from "../ChecklistTitle/ChecklistTitle";
import Subchecklist from "../Subchecklist/Subchecklist";
import BlankSpace from "../BlankSpace/BlankSpace";
import useMemoizedCallback from "../../../hooks/useMemoizedCallback";
import SectionTitle from "../SectionTitle/SectionTitle";
import FrequencyBox from "../FrequencyBox/FrequencyBox";
import AuthContext from "../../../store/auth-context";
import classes from "./ChecklistEditor.module.css";
import LogoPicker from "../../LogoPicker/LogoPicker";
import TextBox from "../TextBox/TextBox";
import ColumnBreak from "../ColumnBreak/ColumnBreak";
import { showError, showInfo } from "../../../utils/notifications";
import {
  handleAuthError,
  handleAxiosError,
} from "../../../utils/errorHandlers";
import { modalStyle } from "../../../utils/modalStyle";

const fileDownload = require("js-file-download");

const ChecklistEditor = (props) => {
  const [dragItemId, setDragItemId] = useState(-1);
  const [draggedOverItemId, setDraggedOverItemId] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subscriptionLimits, setSubscriptionLimits] = useState({
    maxChecklists: 0,
    maxCheckItems: 0,
    logo: false,
  });
  const [numCheckItems, setNumCheckItems] = useState(-1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const onDeleteSubchecklist = props.onDeleteSubchecklist;
  const onDeleteItem = props.onDeleteItem;
  const onAddCheckItem = props.onAddCheckItem;
  const onAddCondition = props.onAddCondition;
  const onAddFrequencyItem = props.onAddFrequencyItem;
  const onDeleteFrequencyItem = props.onDeleteFrequencyItem;
  const maxCheckItems = subscriptionLimits.maxCheckItems;
  const subchecklists = props.subchecklists;
  const saveChecklist = props.onSave;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Handle keyboard commands
  const handleKeyCombinations = useMemoizedCallback(
    (event) => {
      // Save on ctrl+s
      if (event.keyCode === 83 && event.ctrlKey) {
        event.preventDefault();
        saveChecklist();
      }
    },
    [saveChecklist]
  );

  useEffect(() => {
    let mounted = true;
    document.addEventListener("keydown", handleKeyCombinations); // Listen for keyboard events
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/subscriptions/info`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((data) => {
            if (data.data.error) {
              showError("Error", data.data.error);
              console.log(`Error: ${data.data.error}`);
              navigate("/pricing");
            } else {
              if (mounted) setSubscriptionLimits(data.data.limits);
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });

    // Count checkItems and set state for subscriptionLimits
    const items = subchecklists;
    let count = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type === "subchecklist") {
        count += items[i].checkItems.length;
      } else if (items[i].type === "frequencyBox") {
        count += items[i].frequencies.length;
      }
    }
    if (mounted) setNumCheckItems(count);

    return function cleanup() {
      // Set to unmounted to prevent more state updates after component unmount.
      mounted = false;
      // Remove event listener
      document.removeEventListener("keydown", handleKeyCombinations);
    };
  }, [subchecklists, authCtx, navigate, handleKeyCombinations]);

  // If anchorEl is not null, this will be true. False otherwise.
  const open = Boolean(anchorEl);

  // Handler for adding a subchecklist.
  const handleAddSubchecklist = () => {
    props.onAddSubchecklist();
    handleCloseMenu();
  };

  // Handler for adding a FrequencyBox.
  const handleAddFrequencyBox = () => {
    props.onAddFrequencyBox();
    handleCloseMenu();
  };

  // Handler for adding a Section Title.
  const handleAddSectionTitle = () => {
    props.onAddSectionTitle();
    handleCloseMenu();
  };

  // Handler for adding a Blank Space.
  const handleAddBlankSpace = () => {
    props.onAddBlankSpace();
    handleCloseMenu();
  };

  // Handler for adding a TextBox.
  const handleAddTextBox = () => {
    props.onAddTextBox();
    handleCloseMenu();
  };

  // Handler for adding ColumnBreak.
  const handleAddColumnBreak = () => {
    props.onAddColumnBreak();
    handleCloseMenu();
  };

  const handleAddCheckItem = useMemoizedCallback(
    (subchecklistId, item, action) => {
      if (numCheckItems < maxCheckItems) {
        onAddCheckItem(subchecklistId, item, action);
        setNumCheckItems(numCheckItems + 1);
      } else {
        //error
        showInfo("Sorry!", "You cannot add any more Check Items.");
      }
    },
    [numCheckItems, setNumCheckItems, onAddCheckItem, maxCheckItems]
  );

  const handleAddCondition = useMemoizedCallback(
    (subchecklistId, type, text) => {
      if (numCheckItems < maxCheckItems) {
        onAddCondition(subchecklistId, type, text);
        setNumCheckItems(numCheckItems + 1);
      } else {
        //error
        showInfo("Sorry!", "You cannot add any more Check Items.");
      }
    },
    [numCheckItems, setNumCheckItems, onAddCondition, maxCheckItems]
  );

  const handleAddFrequencyItem = useMemoizedCallback(
    (frequencyBoxId, name, frequency) => {
      if (numCheckItems < maxCheckItems) {
        onAddFrequencyItem(frequencyBoxId, name, frequency);
        setNumCheckItems(numCheckItems + 1);
      } else {
        showError("Error", "You cannot add any more Check Items");
      }
    },
    [
      numCheckItems,
      setNumCheckItems,
      onAddFrequencyItem,
      maxCheckItems,
      showError,
    ]
  );

  const handleDeleteCheckItem = useMemoizedCallback(
    (subschecklistId, itemId) => {
      onDeleteItem(subschecklistId, itemId);
      setNumCheckItems(numCheckItems - 1);
    },
    [numCheckItems, setNumCheckItems, onDeleteItem]
  );

  const handleDeleteFrequencyItem = useMemoizedCallback(
    (frequencyBoxId, frequencyItemId) => {
      onDeleteFrequencyItem(frequencyBoxId, frequencyItemId);
      setNumCheckItems(numCheckItems - 1);
    },
    [numCheckItems, setNumCheckItems, onDeleteFrequencyItem]
  );

  const handleDeleteSubchecklist = useMemoizedCallback(
    (subchecklistId, itemCount) => {
      setNumCheckItems(numCheckItems - itemCount);
      onDeleteSubchecklist(subchecklistId);
    },
    [numCheckItems, setNumCheckItems, onDeleteSubchecklist]
  );

  const handleDeleteFrequencyBox = useMemoizedCallback(
    (frequencyBoxId, frequencyCount) => {
      setNumCheckItems(numCheckItems - frequencyCount);
      onDeleteSubchecklist(frequencyBoxId);
    },
    [numCheckItems, setNumCheckItems, onDeleteSubchecklist]
  );

  // Called when the subchecklist is dragged.
  const handleDrag = useCallback(
    (subchecklistId) => {
      setDragItemId(subchecklistId);
    },
    [setDragItemId]
  );

  // Called when another subchecklist is dragged over this subchecklist.
  const handleDragOver = useCallback(
    (subchecklistId) => {
      setDraggedOverItemId(subchecklistId);
    },
    [setDraggedOverItemId]
  );

  // Called when the dragend event fires for a subchecklist. (Memoized so that it doesnt cause tons of re-renders).
  const handleDragEnd = useMemoizedCallback(() => {
    props.onReorderSubchecklists(dragItemId, draggedOverItemId);
  }, [props.onReorderSubchecklists, dragItemId, draggedOverItemId]);

  // Resets the id of the subchecklist being dragged.
  const resetDragItemId = useCallback(() => {
    setDragItemId(-1);
  }, [setDragItemId]);

  const handlePageSizeChange = (event) => {
    props.onPageSizeChange(event.target.value);
  };

  const handleChecklistStyleChange = (event) => {
    props.onChecklistStyleChange(event.target.value);
  };

  const handleColumnChange = (event) => {
    props.onColumnsChange(Number(event.target.value));
  };

  const handleFontSizeChange = (event) => {
    props.onChangeFontSize(Number(event.target.value));
  };

  const handleFontFamilyChange = (event) => {
    props.onChangeFontFamily(event.target.value);
  };

  // Handler for opening the Floating Action Button menu.
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handler for closing the Floating Action Button Menu.
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadPdf = () => {
    setIsDownloading(true);
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${props.id}/pdf`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
            Accept: "application/pdf",
          },
          responseType: "arraybuffer",
        };
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.error) {
              showError("Error", res.data.error);
            } else {
              fileDownload(res.data, `${props.name}.pdf`);
              setIsDownloading(false);
            }
          })
          .catch((err) => {
            setIsDownloading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        setIsDownloading(false);
        handleAuthError(err, navigate, authCtx);
      });
  };

  // Map item data into Subchecklist elements
  const subchecklistElements = subchecklists.map((item) => {
    let returnItem;
    if (item.type === "subchecklist") {
      returnItem = (
        <Subchecklist
          key={item.id}
          subchecklistId={item.id}
          title={item.title}
          color={item.color}
          items={item.checkItems}
          onDeleteSubchecklist={handleDeleteSubchecklist}
          onUpdateSubchecklistTitle={props.onUpdateSubchecklistTitle}
          onChangeColor={props.onChangeSubchecklistColor}
          onToggleBoldCheckItem={props.onToggleBoldCheckItem}
          onToggleItalicCheckItem={props.onToggleItalicCheckItem}
          onItemUpdate={props.onItemUpdate}
          onDeleteItem={handleDeleteCheckItem}
          onAddCheckItem={handleAddCheckItem}
          onAddCondition={handleAddCondition}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onReorderCheckItems={props.onReorderCheckItems}
          resetDragItemId={resetDragItemId}
        />
      );
    } else if (item.type === "blankSpace") {
      returnItem = (
        <BlankSpace
          key={item.id}
          id={item.id}
          height={item.height}
          onUpdateHeight={props.onUpdateBlankSpace}
          onDelete={props.onDeleteSubchecklist}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onReorderCheckItems={props.onReorderCheckItems}
          resetDragItemId={resetDragItemId}
        />
      );
    } else if (item.type === "sectionTitle") {
      returnItem = (
        <SectionTitle
          key={item.id}
          id={item.id}
          title={item.title}
          color={item.color}
          onUpdateTitle={props.onUpdateSectionTitle}
          onChangeColor={props.onChangeSubchecklistColor}
          onDelete={props.onDeleteSubchecklist}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onReorderCheckItems={props.onReorderCheckItems}
          resetDragItemId={resetDragItemId}
        />
      );
    } else if (item.type === "frequencyBox") {
      returnItem = (
        <FrequencyBox
          key={item.id}
          id={item.id}
          title={item.title}
          color={item.color}
          items={item.frequencies}
          onUpdateTitle={props.onUpdateFrequencyBoxTitle}
          onChangeColor={props.onChangeSubchecklistColor}
          onDeleteFrequencyBox={handleDeleteFrequencyBox}
          onAddFrequency={handleAddFrequencyItem}
          onDeleteFrequency={handleDeleteFrequencyItem}
          onUpdateFrequencyItemName={props.onUpdateFrequencyItemName}
          onUpdateFrequencyItemFrequency={props.onUpdateFrequencyItemFrequency}
          onToggleBoldFrequencyItem={props.onToggleBoldFrequencyItem}
          onToggleItalicFrequencyItem={props.onToggleItalicFrequencyItem}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onReorderFrequencyItems={props.onReorderFrequencyItems}
          resetDragItemId={resetDragItemId}
        />
      );
    } else if (item.type === "textBox") {
      returnItem = (
        <TextBox
          key={item.id}
          id={item.id}
          title={item.title}
          markdown={item.markdown}
          color={item.color}
          minlength="0"
          maxlength="5000"
          onUpdateTitle={props.onUpdateSubchecklistTitle}
          onUpdate={props.onUpdateTextBox}
          onChangeColor={props.onChangeSubchecklistColor}
          onDelete={props.onDeleteSubchecklist}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          resetDragItemId={resetDragItemId}
        />
      );
    } else if (item.type === "columnBreak") {
      returnItem = (
        <ColumnBreak
          key={item.id}
          id={item.id}
          onDelete={props.onDeleteSubchecklist}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          resetDragItemId={resetDragItemId}
        />
      );
    }
    return returnItem;
  });

  // Generate FontSize MenuItems
  let fontSizeMenuItems = [];
  for (let i = 5; i <= 25; i += 0.5) {
    fontSizeMenuItems.push(<MenuItem value={i}>{i}</MenuItem>);
  }

  const editorControls = (
    <div className={classes.controls}>
      <div className={classes.selections}>
        <div className={classes.row}>
          <FormControl sx={{ m: 1, minWidth: 110 }}>
            <InputLabel id="checklistStyle-label">Checklist Style</InputLabel>
            <Select
              labelId="checklistStyle-label"
              name="checklistStyle"
              label="Checklist Style"
              value={props.checklistStyle}
              defaultValue={"Dots"}
              onChange={handleChecklistStyleChange}
            >
              <MenuItem value={"Dots"}>Dots</MenuItem>
              <MenuItem
                value={"Table"}
                disabled={!subscriptionLimits.checklistStyles}
              >
                Table
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.row}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="pageSize-label">Page Size</InputLabel>
            <Select
              labelId="pageSize-label"
              name="pageSize"
              label="Page Size"
              value={props.pageSize}
              defaultValue={"Letter"}
              onChange={handlePageSizeChange}
            >
              <MenuItem value={"Letter"}>Letter</MenuItem>
              <MenuItem value={"1/2 Letter"}>1/2 Letter</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="column-label">Columns</InputLabel>
            <Select
              labelId="column-label"
              name="columns"
              label="Columns"
              value={props.columns}
              defaultValue={3}
              onChange={handleColumnChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.row}>
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="fontSize-label">Font Size</InputLabel>
            <Select
              labelId="fontSize-label"
              name="Font size"
              label="Font Size"
              value={props.fontSize}
              defaultValue={10}
              onChange={handleFontSizeChange}
            >
              {fontSizeMenuItems}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 90 }}>
            <InputLabel id="fontFamily-label">Font Family</InputLabel>
            <Select
              labelId="fontFamily-label"
              name="Font family"
              label="Font Family"
              value={props.fontFamily}
              defaultValue={"Arial"}
              onChange={handleFontFamilyChange}
            >
              <MenuItem value="Times New Roman">
                <span className={classes.timesNewRoman}>Times New Roman</span>
              </MenuItem>
              <MenuItem value="Arial">
                <span className={classes.arial}>Arial</span>
              </MenuItem>
              <MenuItem value="Calibri">
                <span className={classes.calibri}>Calibri</span>
              </MenuItem>
              <MenuItem value="Roboto">
                <span className={classes.roboto}>Roboto</span>
              </MenuItem>
              <MenuItem value="Monospace">
                <span className={classes.monospace}>Monospace</span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );

  const controlButtons = (
    <span className={classes.saveButton}>
      <Button variant="contained" color="neutral" onClick={handleOpenModal}>
        <SettingsIcon />
      </Button>
      {/* Show logo picker if the user's subscription allows it or if they are an admin */}
      {(subscriptionLimits.logo || authCtx.isAdmin) && (
        <LogoPicker
          id={props.id}
          logo={props.logo}
          setAlert={props.setAlert}
          setAlertPdf={props.setAlertPdf}
        />
      )}
      <LoadingButton
        variant="contained"
        color="neutral"
        onClick={props.onSave}
        loading={props.isSaving}
      >
        Save
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="neutral"
        onClick={handleDownloadPdf}
        loading={isDownloading}
      >
        Download
      </LoadingButton>
    </span>
  );

  const controlsModal = (
    <Modal open={showModal} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <h3 className={classes.centered}>Settings</h3>
        {editorControls}
        <div className={classes.centered}>
          <Button variant="contained" onClick={handleCloseModal}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );

  const subchecklistData =
    subchecklistElements.length > 0 ? (
      subchecklistElements
    ) : (
      <p className={classes.noItemsText}>No items to display...</p>
    );

  return (
    <div className={classes.container}>
      <div className={classes.editor}>
        <ChecklistTitle
          title={props.name}
          onTitleChange={props.onTitleChange}
          minlength="1"
          maxlength="100"
        />
        {controlButtons}
        {controlsModal}
        <div className={classes.subchecklistContainer}>{subchecklistData}</div>
        <Fab
          variant="circular"
          color="primary"
          aria-label="add"
          sx={{ marginBottom: "10px" }}
          onClick={handleOpenMenu}
        >
          <AddIcon />
        </Fab>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleAddSubchecklist}>Subchecklist</MenuItem>
          <MenuItem onClick={handleAddFrequencyBox}>Frequency Box</MenuItem>
          <MenuItem onClick={handleAddSectionTitle}>Section Title</MenuItem>
          <MenuItem onClick={handleAddTextBox}>Text Box</MenuItem>
          <MenuItem onClick={handleAddBlankSpace}>Blank Space</MenuItem>
          <MenuItem onClick={handleAddColumnBreak}>Column Break</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ChecklistEditor;
