import React, { useContext } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import PdfDisplay from "../../components/PdfDisplay/PdfDisplay";
import classes from "./OrderDetail.module.css";
import AuthContext from "../../store/auth-context";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const fileDownload = require("js-file-download");

const OrderDetail = () => {
  const [order, setOrder] = useState({
    metadata: {},
    stripe_data: {},
    total_details: {
      amount_discount: 0,
      amount_shipping: 0,
      amount_tax: 0,
    },
    currency: "",
    shipping: {
      name: "",
      address: {
        country: "",
        state: "",
        postal_code: "",
        city: "",
        line2: null,
        line1: "",
      },
      // },
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { orderId } = useParams();

  // Get order
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAlert(false);
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/${orderId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.Item) {
              setOrder(res.data.Item);
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [alert, orderId, authCtx, navigate]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateOrderStatus = (status) => {
    if (
      status !== "unfulfilled" &&
      status !== "in-progress" &&
      status !== "fulfilled" &&
      status !== "cancelled"
    )
      return;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/${orderId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        };
        const body = {
          status,
        };
        axios
          .put(url, body, config)
          .then((res) => {
            handleClose();
            setAlert(true);
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const handleDownloadPdf = () => {
    setIsDownloading(true);
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/${orderId}/pdf`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
            Accept: "application/pdf",
          },
          responseType: "arraybuffer",
        };
        axios
          .get(url, config)
          .then((res) => {
            fileDownload(res.data, `checklist_order_${orderId}.pdf`);
            setIsDownloading(false);
          })
          .catch((err) => {
            setIsDownloading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        setIsDownloading(false);
        handleAuthError(err, navigate, authCtx);
      });
  };

  const shipping_address = `${order.shipping.address.line1}, ${order.shipping.address.city}, ${order.shipping.address.state}, ${order.shipping.address.country}, ${order.shipping.address.postal_code}`;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.leftSide}>
          <p className={classes.title}>Order Details</p>
          <div className={classes.controls}>
            <Button
              variant="contained"
              color="neutral"
              size="small"
              onClick={handleOpenMenu}
            >
              Change Status
            </Button>
            <LoadingButton
              variant="contained"
              color="neutral"
              size="small"
              onClick={handleDownloadPdf}
              loading={isDownloading}
            >
              Download
            </LoadingButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleUpdateOrderStatus("unfulfilled");
                }}
              >
                Unfulfilled
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUpdateOrderStatus("in-progress");
                }}
              >
                In Progress
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUpdateOrderStatus("fulfilled");
                }}
              >
                Fulfilled
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUpdateOrderStatus("cancelled");
                }}
              >
                Cancelled
              </MenuItem>
            </Menu>
          </div>
          <div className={classes.mainInfo}>
            <p className={classes.infoItem}>
              <span>Shipping Name: </span>
              <span>{order.shipping.name}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Shipping Address: </span>
              <span>{shipping_address}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Shipping Address (Line 2): </span>
              <span>{order.shipping.address.line2 || "No data..."}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Email: </span>
              <span>{order.email}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Status: </span>
              <span>{order.status}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Pages: </span>
              <span>{order.pages}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Quantity: </span>
              <span>{order.quantity}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Page Size: </span>
              <span>{order.pageSize}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Total: </span>
              <span>
                ${order.amount_total / 100} {order.currency}
              </span>
            </p>
            <p className={classes.infoItem}>
              <span>Item Price: </span>
              <span>
                ${order.amount_subtotal / 100} {order.currency}
              </span>
            </p>
            <p className={classes.infoItem}>
              <span>Discount Amount: </span>
              <span>
                ${order.total_details.amount_discount / 100} {order.currency}
              </span>
            </p>
            <p className={classes.infoItem}>
              <span>Shipping Amount: </span>
              <span>
                ${order.total_details.amount_shipping / 100} {order.currency}
              </span>
            </p>
            <p className={classes.infoItem}>
              <span>Tax Amount: </span>
              <span>
                ${order.total_details.amount_tax / 100} {order.currency}
              </span>
            </p>
            <p className={classes.infoItem}>
              <span>Notes: </span>
              <span>{order.notes === "" ? "N/A" : order.notes}</span>
            </p>
          </div>
          <hr className={classes.divider} />
          <div className={classes.miscInfo}>
            <p className={classes.infoItem}>
              <span>Created Date: </span>
              <span>{order.createdDate}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Created By: </span>
              <span>{order.createdBy}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Last Updated: </span>
              <span>{order.lastUpdatedDate}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Last Updated By: </span>
              <span>{order.lastUpdatedBy}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Order ID: </span>
              <span>{order.id}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Checklist ID: </span>
              <span>{order.checklist_id}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Cognito User ID: </span>
              <span>{order.cognito_user_id}</span>
            </p>
            <p className={classes.infoItem}>
              <span>Stripe Customer ID: </span>
              <span>{order.stripe_customer_id}</span>
            </p>
          </div>
        </div>
        <div className={classes.rightSide}>
          <ErrorBoundary>
            <PdfDisplay orderId={orderId} showZoomControls />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
