import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

import {
  validateEmail,
  validatePassword,
} from "../../validation/formValidation";
import Card from "../UI/Card";
import { showError } from "../../utils/notifications";
import Footer from "../../layout/Footer/Footer";
import classes from "./SignupForm.module.css";

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Start hidden signup enable states.
  const [titleClickCounter, setTitleClickCounter] = useState(0);
  const [subtextClickCounter, setSubtextClickCounter] = useState(0);
  const [timerSet, setTimerSet] = useState(false);
  // End hidden signup enable states.

  const navigate = useNavigate();
  // const signupDisabled = !(process.env.REACT_APP_ENABLE_SIGNUP === "true");
  const [signupDisabled, setSignupDisabled] = useState(
    !(process.env.REACT_APP_ENABLE_SIGNUP === "true")
  );

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    // Quit if signup is disabled
    if (signupDisabled) return;

    const passwordValid = validatePassword(password);

    // Should validate email
    if (!validateEmail(email)) {
      setEmailError("Email should be a valid email address.");
      setIsEmailError(true);
      return;
    } else if (!passwordValid.valid) {
      // Reset email error
      setIsEmailError(false);
      setEmailError("");

      // Set password error
      setPasswordError(passwordValid.error);
      setIsPasswordError(true);
      return;
    } else {
      // Reset errors
      setIsEmailError(false);
      setIsPasswordError(false);
      setEmailError("");
      setPasswordError("");
    }

    if (password !== passwordConfirm) {
      // Make sure passwords match
      showError("Error", "Passwords do not match.");
      return;
    }

    try {
      setIsLoading(true);
      // Call sign up api
      await Auth.signUp({
        username: email,
        password: password,
      });

      setIsLoading(false);

      navigate("/confirm-email", {
        state: { email: email, password: password },
      });
    } catch (err) {
      setIsLoading(false);
      if (err.name === "InvalidPasswordException") {
        showError(
          "Error",
          "Password must have 8 or more characters and one special character"
        );
      } else if (err.name === "InvalidParameterException") {
        showError("Error", err.message);
      } else if (err.name === "UsernameExistsException") {
        showError("Error", "A user already exists with that email.");
      }
    }
  };

  const textFieldStyle = {
    margin: "10px 0",
  };

  const signupDisabledNotice = (
    <p className={classes.signupDisabled}>Sign Up is currently disabled</p>
  );

  ///////////////////////////////////////
  //  START HIDDEN ENABLE SIGNUP CODE  //
  ///////////////////////////////////////

  const handleClickTitle = () => {
    // Increment counter
    setTitleClickCounter(titleClickCounter + 1);
    // If timer isnt set, then set new timer.
    if (!timerSet) {
      setTimeout(() => {
        resetHiddenLoginState();
      }, 5000);
      setTimerSet(true);
    }
  };

  const handleClickSubtext = () => {
    if (titleClickCounter === 9 && subtextClickCounter === 6) {
      setSignupDisabled(false);
      resetHiddenLoginState();
      return;
    }
    if (timerSet) setSubtextClickCounter(subtextClickCounter + 1);
  };

  const resetHiddenLoginState = () => {
    setTitleClickCounter(0);
    setSubtextClickCounter(0);
    setTimerSet(false);
  };

  /////////////////////////////////////
  //  END HIDDEN ENABLE SIGNUP CODE  //
  /////////////////////////////////////

  return (
    <div className={classes.container}>
      <div className={classes.inset}>
        <Card>
          <form className={classes["signup-form"]}>
            <h1 onClick={handleClickTitle}>Sign Up</h1>
            {signupDisabled && signupDisabledNotice}
            <TextField
              fullWidth
              type="text"
              size="small"
              margin="dense"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              error={isEmailError}
              helperText={emailError}
              sx={textFieldStyle}
            />
            <TextField
              fullWidth
              type="password"
              size="small"
              margin="dense"
              label="Password"
              value={password}
              onChange={handlePasswordChange}
              error={isPasswordError}
              helperText={passwordError}
              sx={textFieldStyle}
            />
            <TextField
              fullWidth
              type="password"
              size="small"
              margin="dense"
              label="Confirm Password"
              value={passwordConfirm}
              onChange={handlePasswordConfirmChange}
              sx={textFieldStyle}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="success"
              disabled={signupDisabled}
              onClick={handleSignup}
              loading={isLoading}
            >
              Sign Up
            </LoadingButton>
            <div className={classes["login"]}>
              <label onClick={handleClickSubtext}>
                Already have a FlightCheck account?
              </label>
              <NavLink to="/login">Log in</NavLink>
            </div>
          </form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default SignupForm;
