import { useEffect, useContext, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import AuthContext from "../../store/auth-context";
import StyledLink from "../../components/StyledLink/StyledLink";
import classes from "./AdminUsersPage.module.css";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const AdminUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [estimatedTotalCount, setEstimatedTotalCount] = useState(0);
  const [paginationToken, setPaginationToken] = useState("");
  const [currPaginationToken, setCurrPaginationToken] = useState("");
  const [prevPaginationTokens, setPrevPaginationTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [reloadUsers, setReloadUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUsers = async () => {
      setReloadUsers(false);
      setLoading(true);
      try {
        const session = await Auth.currentSession();
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/users`;

        // Add email or username if one is specified (username takes precedence).
        if (username) {
          url += `?username=${username}`;
          resetPagingState();
        } else if (email) {
          url += `?email=${email}`;
          resetPagingState();
        } else {
          // Add paginationToken if we have one.
          // We only add pagination token if username and email are empty.
          if (currPaginationToken !== "" && !username && !email) {
            url += `?paginationtoken=${encodeURIComponent(
              currPaginationToken
            )}`;
          }
        }

        const config = {
          headers: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            setUsers(res.data.Users);
            setCount(res.data.Count);
            setEstimatedTotalCount(res.data.EstimatedNumberOfUsers);
            setPaginationToken(res.data.PaginationToken);
            setLoading(false);
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      } catch (err) {
        console.error(err);
        setLoading(false);
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadUsers();
  }, [currPaginationToken, reloadUsers, navigate, authCtx]);

  const goToNextPage = () => {
    if (!paginationToken) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    newPrevPaginationTokens.push(currPaginationToken);
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(paginationToken);
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage === 0) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    const newCurrPaginationToken = newPrevPaginationTokens.pop();
    const newPaginationToken = currPaginationToken;
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(newCurrPaginationToken);
    setPaginationToken(newPaginationToken);
    setCurrentPage(currentPage - 1);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const resetPagingState = () => {
    setPaginationToken("");
    setCurrPaginationToken("");
    setPrevPaginationTokens([]);
    setCurrentPage(0);
  };

  const searchBar = (
    <Stack
      className={classes.searchBar}
      direction="row"
      spacing={1}
      justifyContent="center"
      marginTop="20px"
      paddingX="20px"
    >
      <TextField
        label="Username"
        variant="outlined"
        size="small"
        onChange={handleUsernameChange}
      />
      <TextField
        label="Email"
        variant="outlined"
        size="small"
        onChange={handleEmailChange}
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setReloadUsers(true);
        }}
      >
        Search
      </Button>
    </Stack>
  );

  const usersTable = (
    <ErrorBoundary>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.backlink}>
            <NavLink to="/admin">
              <ArrowBackIcon />
              <span>Back</span>
            </NavLink>
          </div>
          <h1 className={classes.title}>Users</h1>
        </div>
        <p className={classes.title}>Page Total: {count}</p>
        <p className={classes.title}>Estimated Total: {estimatedTotalCount}</p>
        {searchBar}
        <TableContainer className={classes.usersTable} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Verified</TableCell>
                <TableCell align="right">Enabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.Username}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">
                    <StyledLink to={`/admin/users/${user.Username}`}>
                      {user.Username}
                    </StyledLink>
                  </TableCell>
                  <TableCell align="right">
                    {user.Attributes[2].Value}
                  </TableCell>
                  <TableCell align="right">{user.UserCreateDate}</TableCell>
                  <TableCell align="right">
                    {user.Attributes[1].Value ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="right">
                    {user.Enabled ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          marginBottom="20px"
        >
          <IconButton onClick={goToPreviousPage} disabled={currentPage === 0}>
            <NavigateBeforeIcon />
          </IconButton>
          <div className={classes.pageNumber}>
            <div>{currentPage + 1}</div>
          </div>
          <IconButton onClick={goToNextPage} disabled={!paginationToken}>
            <NavigateNextIcon />
          </IconButton>
        </Stack>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </ErrorBoundary>
  );

  return usersTable;
};

export default AdminUsersPage;
