import { Button, Modal, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Auth } from "aws-amplify";
import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import { showSuccess, showError } from "../../utils/notifications";
import AuthContext from "../../store/auth-context";
import { modalStyle } from "../../utils/modalStyle";
import classes from "./LogoPicker.module.css";

// Allow user to pick logo.
// Should immediately upload once picked.
// When a logo exists in a checklist there should be an icon that shows its presence.
// There should be an x button on the icon which would delete the logo when pressed.
// The choose file button should remain there, and if a logo is uploaded when one already exists, the old one should be deleted.
// Loading spinner should show when uploading or deleting icon
const LogoPicker = (props) => {
  const [open, setOpen] = useState(false);

  const authCtx = useContext(AuthContext);
  const fileInputRef = useRef();
  const navigate = useNavigate();

  const handleLogoUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    uploadFile(event.target.files[0]);
  };

  const uploadFile = (file) => {
    if (file === null) return;

    Auth.currentSession()
      .then((res) => {
        // Create an object of formData
        let formData = new FormData();

        // Update the formData object
        formData.append("file", file);

        axios
          .post(
            `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${props.id}/logo`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${res.accessToken.jwtToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              showError("Logo upload failed", res.data.error);
            } else {
              props.setAlert(true);
              props.setAlertPdf(true);
              showSuccess("Success", "Logo uploaded.");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const handleDeleteCurrentLogo = () => {
    Auth.currentSession()
      .then((res) => {
        axios
          .delete(
            `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${props.id}/logo`,
            {
              headers: {
                Authorization: `Bearer ${res.accessToken.jwtToken}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              showError("Logo removal failed", res.data.error);
            } else {
              props.setAlert(true);
              props.setAlertPdf(true);
              showSuccess("Success", "Logo deleted.");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const currentLogo = props.logo ? (
    <div className={classes.currentLogo}>
      <span className={classes.logoFilename}>
        {props.logo.original_filename}
      </span>
      <span className={classes.deleteIcon} onClick={handleDeleteCurrentLogo}>
        <ClearIcon />
      </span>
    </div>
  ) : (
    <p>No current logo...</p>
  );

  return (
    <span className={classes.container}>
      <input
        className={classes.input}
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <Button variant="contained" color="neutral" onClick={() => setOpen(true)}>
        Logo
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <p className={classes.modalTitle}>Checklist Logo</p>
          <Button variant="contained" onClick={handleLogoUploadClick}>
            Choose File
          </Button>
          {currentLogo}
        </Box>
      </Modal>
    </span>
  );
};

export default LogoPicker;
