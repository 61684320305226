import React from "react";
import { useState, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CheckItem from "../CheckItem/CheckItem";
import Condition from "../Condition/Condition";
import SubchecklistTitle from "../SubchecklistTitle/SubchecklistTitle";
import classes from "./Subchecklist.module.css";
import ColorPicker from "../ColorPicker/ColorPicker";

const Subchecklist = React.memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [draggedCheckItemId, setDraggedCheckItemId] = useState("");
  const [draggedOverCheckItemId, setDraggedOverCheckItemId] = useState("");
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // If anchorEl is not null, this will be true. False otherwise.
  const open = Boolean(anchorEl);

  // Destructuring some props for useCallback.
  const subchecklistId = props.subchecklistId;
  const onAddCheckItem = props.onAddCheckItem;
  const onAddCondition = props.onAddCondition;
  const onDeleteSubchecklist = props.onDeleteSubchecklist;
  const onReorderCheckItems = props.onReorderCheckItems;

  // Handles the onClick event for the "Add Check Item" button.
  const handleAddCheckItem = () => {
    onAddCheckItem(subchecklistId, "item", "action");
    handleCloseMenu();
  };

  // Handles the onClick event for the "Delete" button on the subchecklist.
  const handleDeleteSubchecklist = () => {
    const itemCount = props.items.length;
    onDeleteSubchecklist(subchecklistId, itemCount);
  };

  // Handles the onChange event for the color picker input.
  const handleChangeColor = (color) => {
    props.onChangeColor(subchecklistId, color);
  };

  // Handles the onClick event for the "add precondition" button.
  const handleAddPrecondition = () => {
    onAddCondition(subchecklistId, "precondition", "precondition");
    handleCloseMenu();
  };

  // Handles the onClick event for the "add postcondition" button.
  const handleAddPostcondition = () => {
    onAddCondition(subchecklistId, "postcondition", "postcondition");
    handleCloseMenu();
  };

  // Handles the mousedown event on the subchecklist drag handle.
  const handleMouseDown = () => {
    setIsDraggable(true);
  };

  // Handles the ondrag event for the subchecklist.
  const handleDrag = () => {
    props.onDrag(subchecklistId);
  };

  // Handles the dragover event for the subchecklist (when another subchecklist is dragged over this subchecklist).
  const handleDragOver = (event) => {
    event.stopPropagation();
    if (!isDraggedOver) {
      setIsDraggedOver(true);
      props.onDragOver(subchecklistId);
    }
  };

  // Handles the onDragLeave event for the subchecklist.
  const handleDragLeave = (event) => {
    if (isDraggedOver) {
      setIsDraggedOver(false);
    }
  };

  // Handles the dragend event when the user stops dragging the subchecklist.
  const handleDragEnd = () => {
    setIsDraggable(false);
    props.onDragEnd();
    props.resetDragItemId();
  };

  // Handles the mouseup event on the subchecklist drag handle.
  const handleMouseUp = () => {
    setIsDraggable(false);
  };

  // Handles the onDrag event for checkItems.
  const handleDragCheckItem = useCallback(
    (checkItemId) => {
      if (draggedCheckItemId !== checkItemId) {
        setDraggedCheckItemId(checkItemId);
      }
    },
    [draggedCheckItemId, setDraggedCheckItemId]
  );

  // Handles the onDragOver event for checkItems.
  const handleDragOverCheckItem = useCallback(
    (checkItemId) => {
      if (draggedOverCheckItemId !== checkItemId) {
        setDraggedOverCheckItemId(checkItemId);
      }
    },
    [draggedOverCheckItemId, setDraggedOverCheckItemId]
  );

  // Handler for opening the Floating Action Button menu.
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handler for closing the Floating Action Button Menu.
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Handles the dragEnd event for checkItems.
  const handleCheckItemDragEnd = useCallback(() => {
    onReorderCheckItems(
      subchecklistId,
      draggedCheckItemId,
      draggedOverCheckItemId
    );
  }, [
    onReorderCheckItems,
    subchecklistId,
    draggedCheckItemId,
    draggedOverCheckItemId,
  ]);

  // Resets the draggedCheckItemId.
  const resetDraggedCheckItemId = useCallback(() => {
    setDraggedCheckItemId("");
  }, [setDraggedCheckItemId]);

  // Handles the onClick event for the subchecklist drag handle.
  const handleDragHandleClick = (event) => {
    event.stopPropagation();
  };

  // Map the checkItems into either a checkItem, precondition or postcondition.
  let items = props.items.map((item) => {
    let returnItem = null;
    if (item.type === "checkItem") {
      return (
        <CheckItem
          id={item.id}
          className={classes.checkItem}
          key={item.id}
          itemId={item.id}
          subchecklistId={subchecklistId}
          item={item.item}
          action={item.action}
          onItemUpdate={props.onItemUpdate}
          onDeleteItem={props.onDeleteItem}
          onToggleBold={props.onToggleBoldCheckItem}
          onToggleItalic={props.onToggleItalicCheckItem}
          isBold={item.bold}
          isItalic={item.italic}
          onDrag={handleDragCheckItem}
          onDragOver={handleDragOverCheckItem}
          onDragEnd={handleCheckItemDragEnd}
          resetDraggedId={resetDraggedCheckItemId}
        />
      );
    } else if (item.type === "checkItemPrecondition") {
      returnItem = (
        <Condition
          id={item.id}
          className={classes.condition}
          key={item.id}
          itemId={item.id}
          subchecklistId={subchecklistId}
          type="precondition"
          value={item.text}
          onItemUpdate={props.onItemUpdate}
          onDeleteItem={props.onDeleteItem}
          onToggleBold={props.onToggleBoldCheckItem}
          onToggleItalic={props.onToggleItalicCheckItem}
          isBold={item.bold}
          isItalic={item.italic}
          onDrag={handleDragCheckItem}
          onDragOver={handleDragOverCheckItem}
          onDragEnd={handleCheckItemDragEnd}
          resetDraggedId={resetDraggedCheckItemId}
        />
      );
    } else if (item.type === "checkItemPostcondition") {
      returnItem = (
        <Condition
          id={item.id}
          className={classes.condition}
          key={item.id}
          itemId={item.id}
          subchecklistId={subchecklistId}
          type="postcondition"
          value={item.text}
          onItemUpdate={props.onItemUpdate}
          onDeleteItem={props.onDeleteItem}
          onToggleBold={props.onToggleBoldCheckItem}
          onToggleItalic={props.onToggleItalicCheckItem}
          isBold={item.bold}
          isItalic={item.italic}
          onDrag={handleDragCheckItem}
          onDragOver={handleDragOverCheckItem}
          onDragEnd={handleCheckItemDragEnd}
          resetDraggedId={resetDraggedCheckItemId}
        />
      );
    }
    return returnItem;
  });

  // Filter out (almost impossible) null values from 'items'
  // items = items.filter((item) => item != null);

  const draggedOverStyle = isDraggedOver ? { background: "lightgrey" } : {};

  return (
    <div
      id={subchecklistId}
      key={subchecklistId}
      className={classes.subchecklist}
    >
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        sx={{ border: "1px solid lightgrey" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          sx={draggedOverStyle}
          className={classes.title}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          draggable={isDraggable}
        >
          <span
            className={classes.handle}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onClick={handleDragHandleClick}
          >
            <DragHandleIcon />
          </span>
          <SubchecklistTitle
            title={props.title}
            subchecklistId={subchecklistId}
            onTitleChange={props.onUpdateSubchecklistTitle}
            minlength="1"
            maxlength="50"
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.controls}>
            <div className={classes.delete}>
              <Button
                color="danger"
                variant="contained"
                size="small"
                onClick={handleDeleteSubchecklist}
              >
                Delete
              </Button>
            </div>
            <ColorPicker color={props.color} onChange={handleChangeColor} />
          </div>
          <div className={classes.subchecklist}>
            {items.length > 0 ? (
              items
            ) : (
              <p>There are no Check Items, add one to get started...</p>
            )}
          </div>
          <Fab color="primary" aria-label="add" onClick={handleOpenMenu}>
            <AddIcon />
          </Fab>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleAddCheckItem}>Check Item</MenuItem>
            <MenuItem onClick={handleAddPrecondition}>Precondition</MenuItem>
            <MenuItem onClick={handleAddPostcondition}>Postcondition</MenuItem>
          </Menu>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default Subchecklist;
