const Joi = require("joi");

// Schema for subchecklist object.
const subchecklistSchema = Joi.object({
  id: Joi.string().guid(),
  type: "subchecklist",
  title: Joi.string().min(1).max(50).required(),
  color: Joi.string().max(50).required(),
  checkItems: Joi.array().items(
    Joi.object({
      id: Joi.string().guid().required(),
      type: "checkItem",
      item: Joi.string().min(1).max(50).required(),
      action: Joi.string().min(1).max(50).required(),
      bold: Joi.boolean().required(),
      italic: Joi.boolean().required(),
    }),
    Joi.object({
      id: Joi.string().guid().required(),
      type: "checkItemPrecondition",
      text: Joi.string().min(1).max(150).required(),
      bold: Joi.boolean().required(),
      italic: Joi.boolean().required(),
    }),
    Joi.object({
      id: Joi.string().guid().required(),
      type: "checkItemPostcondition",
      text: Joi.string().min(1).max(150).required(),
      bold: Joi.boolean().required(),
      italic: Joi.boolean().required(),
    })
  ),
});

// Schema for the section title object.
const sectionTitleSchema = Joi.object({
  id: Joi.string().guid().required(),
  type: "sectionTitle",
  title: Joi.string().min(1).max(50).required(),
  color: Joi.string().max(50).required(),
});

// Schema for the frequency box object.
const frequencyBoxSchema = Joi.object({
  id: Joi.string().guid().required(),
  type: "frequencyBox",
  title: Joi.string().min(1).max(50).required(),
  color: Joi.string().max(50).required(),
  frequencies: Joi.array().items(
    Joi.object({
      id: Joi.string().guid().required(),
      name: Joi.string().min(1).max(50).required(),
      frequency: Joi.string().min(1).max(50).required(),
      bold: Joi.boolean().required(),
      italic: Joi.boolean().required(),
    })
  ),
});

// Schema for the blank space object.
const blankSpaceSchema = Joi.object({
  id: Joi.string().guid().required(),
  type: "blankSpace",
  height: Joi.number().min(1).max(500).required(),
});

// Schema for the TextBox object.
const textBoxSchema = Joi.object({
  id: Joi.string().guid().required(),
  type: "textBox",
  title: Joi.string().min(1).max(50).required(),
  color: Joi.string().max(50).required(),
  markdown: Joi.string().min(0).max(5000).required(),
});

// Schema for the columnBreak object.
const columnBreakSchema = Joi.object({
  id: Joi.string().guid().required(),
  type: "columnBreak",
});

// Schema for the checklist object.
const checklistSchema = Joi.object({
  name: Joi.string().min(1).max(100).required(),
  pageSize: Joi.string().valid("Letter", "1/2 Letter").required(),
  checklistStyle: Joi.string().valid("Dots", "Table").required(),
  columns: Joi.number().valid(1, 2, 3, 4, 5, 6).required(),
  fontSize: Joi.number().min(5).max(25),
  fontFamily: Joi.string()
    .valid("Times New Roman", "Arial", "Roboto", "Monospace", "Calibri")
    .required(),
  content: Joi.array()
    .items(
      subchecklistSchema,
      sectionTitleSchema,
      frequencyBoxSchema,
      blankSpaceSchema,
      textBoxSchema,
      columnBreakSchema
    )
    .required(),
  logo: Joi.object({
    original_filename: Joi.string().min(3).max(1000).required(),
    filename: Joi.string().min(3).max(1000).required(),
  }).optional(),
});

export default checklistSchema;
