import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import classes from "./AdminPaymentUsers.module.css";
import AuthContext from "../../store/auth-context";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const AdminPaymentUsersPage = () => {
  const [paymentUsers, setPaymentUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cognitoUserId, setCognitoUserId] = useState("");
  const [reloadPaymentUsers, setReloadPaymentUsers] = useState(false);

  // Pagination state
  const [count, setCount] = useState(0);
  const [approximateTotalCount, setApproximateTotalCount] = useState(0);
  const [paginationToken, setPaginationToken] = useState("");
  const [currPaginationToken, setCurrPaginationToken] = useState("");
  const [prevPaginationTokens, setPrevPaginationTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setReloadPaymentUsers(false);
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/paymentusers`;

        // Add paginationToken if we have one.
        if (currPaginationToken !== "" && cognitoUserId === "") {
          url += `?lastkey=${encodeURIComponent(currPaginationToken)}`;
        }

        // Set cognitoUserId if it's used
        if (cognitoUserId !== "") {
          url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/paymentusers/cognito/${cognitoUserId}`;
          resetPagingState();
        }

        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            setPaymentUsers(cognitoUserId ? [res.data.Item] : res.data.Items);
            setCount(res.data.Count);
            setApproximateTotalCount(res.data.ApproximateTotalCount);
            setPaginationToken(
              res.data.LastEvaluatedKey
                ? res.data.LastEvaluatedKey.cognito_user_id
                : ""
            );
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [
    authCtx,
    navigate,
    setPaymentUsers,
    setCount,
    setApproximateTotalCount,
    setPaginationToken,
    setIsLoading,
    currPaginationToken,
    reloadPaymentUsers,
  ]);

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const handleCognitoUserIdChange = (event) => {
    setCognitoUserId(event.target.value);
  };

  const goToNextPage = () => {
    if (!paginationToken) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    newPrevPaginationTokens.push(currPaginationToken);
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(paginationToken);
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage === 0) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    const newCurrPaginationToken = newPrevPaginationTokens.pop();
    const newPaginationToken = currPaginationToken;
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(newCurrPaginationToken);
    setPaginationToken(newPaginationToken);
    setCurrentPage(currentPage - 1);
  };

  const resetPagingState = () => {
    setPaginationToken("");
    setCurrPaginationToken("");
    setPrevPaginationTokens([]);
    setCurrentPage(0);
  };

  const loadingBackdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const searchBar = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginTop="20px"
      paddingX="20px"
      className={classes.searchBar}
    >
      <TextField
        label="Cognito User ID"
        variant="outlined"
        size="small"
        onChange={handleCognitoUserIdChange}
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setReloadPaymentUsers(true);
        }}
      >
        Search
      </Button>
    </Stack>
  );

  const pageNavigator = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginBottom="20px"
    >
      <IconButton onClick={goToPreviousPage} disabled={currentPage === 0}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className={classes.pageNumber}>
        <div>{currentPage + 1}</div>
      </div>
      <IconButton onClick={goToNextPage} disabled={!paginationToken}>
        <NavigateNextIcon />
      </IconButton>
    </Stack>
  );

  const paymentUsersTable =
    paymentUsers.length === 0 ? (
      <p>No PaymentUsers to show</p>
    ) : (
      <ErrorBoundary>
        <TableContainer component={Paper} className={classes.paymentUsersTable}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="right">Cognito User ID</TableCell>
                <TableCell align="right">Stripe Customer ID</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentUsers.map((paymentUser) => (
                <TableRow
                  key={paymentUser.cognito_user_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">{paymentUser.email}</TableCell>
                  <TableCell align="right">
                    {paymentUser.cognito_user_id}
                  </TableCell>
                  <TableCell align="right">
                    {paymentUser.stripe_customer_id}
                  </TableCell>
                  <TableCell align="right">
                    {getShortDateString(paymentUser.createdDate)}
                  </TableCell>
                  <TableCell align="right">
                    {getShortDateString(paymentUser.lastUpdatedDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.backlink}>
            <NavLink to="/admin">
              <ArrowBackIcon />
              <span>Back</span>
            </NavLink>
          </div>
          <h1 className={classes.title}>Payment Users</h1>
        </div>
        <p className={classes.title}>Page Total: {count}</p>
        <p className={classes.title}>
          Approximate Total: {approximateTotalCount}
        </p>
        {searchBar}
        {paymentUsersTable}
        {pageNavigator}
      </div>
      {loadingBackdrop}
    </div>
  );
};

export default AdminPaymentUsersPage;
