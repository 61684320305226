import { useState, memo } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SubchecklistTitle from "../SubchecklistTitle/SubchecklistTitle";
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from "./SectionTitle.module.css";

const SectionTitle = memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  // Handles the onChange event for the color picker input.
  const handleChangeColor = (color) => {
    props.onChangeColor(props.id, color);
  };

  // Handles the mousedown event for the drag handle.
  const handleMouseDownDragHandle = (event) => {
    event.stopPropagation();
    setIsDraggable(true);
  };

  // Handles the mouseup event for the drag handle.
  const handleMouseUpDragHandle = (event) => {
    event.stopPropagation();
    setIsDraggable(false);
  };

  // Handles the ondrag event.
  const handleDrag = (event) => {
    event.stopPropagation();
    props.onDrag(props.id);
  };

  // Handles the dragover event.
  const handleDragOver = (event) => {
    event.stopPropagation();
    if (!isDraggedOver) {
      setIsDraggedOver(true);
      props.onDragOver(props.id);
    }
  };

  // Handles the dragleave event.
  const handleDragLeave = (event) => {
    event.stopPropagation();
    if (isDraggedOver) {
      setIsDraggedOver(false);
    }
  };

  // Handles the dragend event.
  const handleDragEnd = (event) => {
    event.stopPropagation();
    if (isDraggable) {
      setIsDraggable(false);
      props.onDragEnd();
      props.resetDragItemId();
    }
  };

  // Handles the onClick event for the subchecklist drag handle.
  const handleDragHandleClick = (event) => {
    event.stopPropagation();
  };

  // Handle the onClick event to delete the sectionTitle.
  const handleDelete = () => {
    props.onDelete(props.id);
  };

  const draggedOverStyle = isDraggedOver ? { background: "lightgrey" } : {};

  return (
    <div id={props.id} className={classes.sectionTitle}>
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        sx={{ border: "1px solid lightgrey" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          sx={draggedOverStyle}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          draggable={isDraggable}
        >
          <div className={classes.summary}>
            <span
              className={classes.handle}
              onMouseDown={handleMouseDownDragHandle}
              onMouseUp={handleMouseUpDragHandle}
              onClick={handleDragHandleClick}
            >
              <DragHandleIcon />
            </span>
            <SubchecklistTitle
              subchecklistId={props.id}
              title={props.title}
              onTitleChange={props.onUpdateTitle}
              minlength="1"
              maxlength="50"
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.controls}>
            <div className={classes.delete}>
              <Button
                variant="contained"
                color="danger"
                size="small"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
            <ColorPicker color={props.color} onChange={handleChangeColor} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default SectionTitle;
