import { useParams } from "react-router-dom";
import OrderView from "../../components/OrderView/OrderView";

import classes from "./Order.module.css";

const Order = () => {
  const { checklistId } = useParams();
  return (
    <div className={classes.container}>
      <OrderView checklistId={checklistId} />
    </div>
  );
};

export default Order;
