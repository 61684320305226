import { Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";

import Home from "./pages/Home/Home";
import AllChecklists from "./pages/AllChecklists/AllChecklists";
import Layout from "./layout/Layout";
import ChecklistDetail from "./pages/ChecklistDetail/ChecklistDetail";
import Login from "./pages/Auth/Login/Login";
import Signup from "./pages/Auth/Signup/Signup";
import PasswordReset from "./pages/Auth/PasswordReset/PasswordReset";
import EmailConfirmation from "./pages/EmailConfirmation/EmailConfirmation";
import MyAccount from "./pages/MyAccount/MyAccount";
import Pricing from "./pages/Pricing/Pricing";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import Order from "./pages/Order/Order";
import Guide from "./pages/Guide/Guide";
import NotFound from "./pages/NotFound/NotFound";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import "./App.css";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import { Fragment } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/muiTheme";
import AdminChecklistsPage from "./pages/AdminChecklistsPage/AdminChecklistsPage";
import AdminPaymentUsersPage from "./pages/AdminPaymentUsersPage/AdminPaymentUsers";
import AdminUsersPage from "./pages/AdminUsersPage/AdminUsersPage";
import UserDetailPage from "./pages/UserDetailPage/UserDetailPage";
import AdminOrdersPage from "./pages/AdminOrdersPage/AdminOrdersPage";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import awsconfig from "./config/aws-amplify-config";

// Configure Amplify
Amplify.configure(awsconfig);

function App() {
  return (
    <Fragment>
      <ReactNotifications />
      <ThemeProvider theme={theme}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Navigate to="/" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/confirm-email" element={<EmailConfirmation />} />
            <Route path="/guide" element={<Guide />} />
            <Route
              path="/checklists"
              element={
                <RequireAuth>
                  <AllChecklists />
                </RequireAuth>
              }
            />
            <Route
              path="/checklists/:checklistId"
              element={
                <RequireAuth>
                  <ChecklistDetail />
                </RequireAuth>
              }
            />
            <Route
              path="/myaccount"
              element={
                <RequireAuth>
                  <MyAccount />
                </RequireAuth>
              }
            />
            <Route
              path="/success"
              element={
                <RequireAuth>
                  <SuccessPage />
                </RequireAuth>
              }
            />
            <Route
              path="/order/:checklistId"
              element={
                <RequireAuth>
                  <Order />
                </RequireAuth>
              }
            />
            <Route
              path="/subscription"
              element={
                <RequireAuth>
                  <SubscriptionPage />
                </RequireAuth>
              }
            />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/admin"
              element={
                <RequireAuth adminOnly>
                  <AdminPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/checklists"
              element={
                <RequireAuth adminOnly>
                  <AdminChecklistsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/paymentusers"
              element={
                <RequireAuth adminOnly>
                  <AdminPaymentUsersPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/users"
              element={
                <RequireAuth adminOnly>
                  <AdminUsersPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/users/:userId"
              element={
                <RequireAuth adminOnly>
                  <UserDetailPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <RequireAuth adminOnly>
                  <AdminOrdersPage />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/orders/:orderId"
              element={
                <ErrorBoundary>
                  <RequireAuth adminOnly>
                    <OrderDetail />
                  </RequireAuth>
                </ErrorBoundary>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
