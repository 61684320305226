import { Button, Modal, Box, Stack } from "@mui/material";
import React, { useState, Fragment } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

import { modalStyle } from "../../../utils/modalStyle";
import classes from "./ColorPicker.module.css";

const ColorPicker = (props) => {
  const [newColor, setNewColor] = useState(props.color);
  const [showModal, setShowModal] = useState(false);

  const color = props.color;
  const setColor = props.onChange;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateColor = () => {
    setColor(newColor);
    closeModal();
  };

  const colorPickerModal = (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={modalStyle}>
        <HexColorPicker
          className={classes.colorInput}
          color={newColor}
          onChange={setNewColor}
        />
        <HexColorInput
          className={classes.hexInput}
          color={newColor}
          onChange={setNewColor}
        />
        <div
          className={classes.colorBar}
          style={{ background: newColor }}
        ></div>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="outlined"
            color="neutral"
            size="medium"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="neutral"
            size="medium"
            onClick={updateColor}
          >
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );

  return (
    <Fragment>
      <div className={classes.buttonOuter} onClick={openModal}>
        <div
          className={classes.buttonInner}
          style={{ background: color }}
        ></div>
      </div>
      {colorPickerModal}
    </Fragment>
  );
};

export default ColorPicker;
