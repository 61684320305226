const validateEmail = (email) => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regx.test(email.toLowerCase());
};

const validatePassword = (password) => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const alphabetUpper = alphabet.toUpperCase();
  const numbers = "1234567890";
  const specialCharacters = "!@#$%^&*-_=+?";
  let lowercaseCount = 0;
  let uppercaseCount = 0;
  let numberCount = 0;
  let specialCharacterCount = 0;
  if (password.length < 8 || password.length > 90) {
    return {
      valid: false,
      error: "Password must be between 8 and 90 characters long.",
    };
  }

  // Count number of lowercase, uppercase, numbers, and special characters.
  for (let i = 0; i < password.length; i++) {
    if (alphabet.indexOf(password[i]) !== -1) {
      lowercaseCount += 1;
    } else if (alphabetUpper.indexOf(password[i]) !== -1) {
      uppercaseCount += 1;
    } else if (numbers.indexOf(password[i]) !== -1) {
      numberCount += 1;
    } else if (specialCharacters.indexOf(password[i]) !== -1) {
      specialCharacterCount += 1;
    }
  }

  if (lowercaseCount < 1) {
    return {
      valid: false,
      error: "Password must have at least one lowercase letter.",
    };
  } else if (uppercaseCount < 1) {
    return {
      valid: false,
      error: "Password must have at least one uppercase letter.",
    };
  } else if (numberCount < 1) {
    return {
      valid: false,
      error: "Password must have at least one number.",
    };
  } else if (specialCharacterCount < 1) {
    return {
      valid: false,
      error: `Password must have at least one of the following characters: ${specialCharacters}`,
    };
  } else {
    return { valid: true };
  }
};

const validateConfirmationCode = (code) => {
  const regx = /^\d{6}$/;
  return regx.test(code);
};

export { validateEmail, validatePassword, validateConfirmationCode };
