import React from "react";
import { useState, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";

import FrequencyItem from "../FrequencyItem/FrequencyItem";
import SubchecklistTitle from "../SubchecklistTitle/SubchecklistTitle";
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from "./FrequencyBox.module.css";

const FrequencyBox = React.memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState("");
  const [draggedOverItemId, setDraggedOverItemId] = useState("");
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  // Destructure props
  const idOfFrequencyBox = props.id;
  const onReorderFrequencyItems = props.onReorderFrequencyItems;

  // Handles the onclick event for the "Add Frequency" button.
  const handleAddFrequency = () => {
    props.onAddFrequency(idOfFrequencyBox, "name", "frequency");
  };

  // Handles the onClick event for the "Delete" button on the FrequencyBox.
  const handleDeleteFrequencyBox = () => {
    const frequencyCount = props.items.length;
    props.onDeleteFrequencyBox(idOfFrequencyBox, frequencyCount);
  };

  // Handles the onChange event for the color picker input.
  const handleChangeColor = (color) => {
    props.onChangeColor(idOfFrequencyBox, color);
  };

  // Handles the mousedown event on the FrequencyBox drag handle.
  const handleMouseDown = () => {
    setIsDraggable(true);
  };

  // Handles the ondrag event for the FrequencyBox.
  const handleDrag = () => {
    props.onDrag(idOfFrequencyBox);
  };

  // Handles the dragover event for the subchecklist (when another subchecklist is dragged over this subchecklist).
  const handleDragOver = (event) => {
    event.stopPropagation();
    if (!isDraggedOver) {
      setIsDraggedOver(true);
      props.onDragOver(idOfFrequencyBox);
    }
  };

  // Handles the onDragLeave event for the subchecklist.
  const handleDragLeave = (event) => {
    if (isDraggedOver) {
      setIsDraggedOver(false);
    }
  };

  // Handles the dragend event when the user stops dragging the subchecklist.
  const handleDragEnd = () => {
    setIsDraggable(false);
    props.onDragEnd();
    props.resetDragItemId();
  };

  // Handles the mouseup event on the subchecklist drag handle.
  const handleMouseUp = () => {
    setIsDraggable(false);
  };

  // Handles the onDrag event for checkItems.
  const handleDragFrequencyItem = useCallback(
    (frequencyItemId) => {
      if (draggedItemId !== frequencyItemId) {
        setDraggedItemId(frequencyItemId);
      }
    },
    [draggedItemId, setDraggedItemId]
  );

  // Handles the onDragOver event for checkItems.
  const handleDragOverFrequencyItem = useCallback(
    (frequencyItemId) => {
      if (draggedOverItemId !== frequencyItemId) {
        setDraggedOverItemId(frequencyItemId);
      }
    },
    [draggedOverItemId, setDraggedOverItemId]
  );

  // Handles the dragEnd event for checkItems.
  const handleFrequencyItemDragEnd = useCallback(() => {
    onReorderFrequencyItems(idOfFrequencyBox, draggedItemId, draggedOverItemId);
  }, [
    onReorderFrequencyItems,
    idOfFrequencyBox,
    draggedItemId,
    draggedOverItemId,
  ]);

  // Resets the draggedCheckItemId.
  const resetDraggedItemId = useCallback(() => {
    setDraggedItemId("");
  }, [setDraggedItemId]);

  // Handles the onClick event for the subchecklist drag handle.
  const handleDragHandleClick = (event) => {
    event.stopPropagation();
  };

  // Map the checkItems into either a checkItem, precondition or postcondition.
  let items = props.items.map((item) => {
    return (
      <FrequencyItem
        key={item.id}
        id={item.id}
        name={item.name}
        frequency={item.frequency}
        idOfFrequencyBox={idOfFrequencyBox}
        onNameUpdate={props.onUpdateFrequencyItemName}
        onFrequencyUpdate={props.onUpdateFrequencyItemFrequency}
        onDeleteItem={props.onDeleteFrequency}
        onToggleBold={props.onToggleBoldFrequencyItem}
        onToggleItalic={props.onToggleItalicFrequencyItem}
        isBold={item.bold}
        isItalic={item.italic}
        onDrag={handleDragFrequencyItem}
        onDragOver={handleDragOverFrequencyItem}
        onDragEnd={handleFrequencyItemDragEnd}
        resetDraggedId={resetDraggedItemId}
      />
    );
  });

  const draggedOverStyle = isDraggedOver ? { background: "lightgrey" } : {};

  return (
    <div
      id={props.id}
      key={props.frequencyBoxId}
      className={classes.frequencyBox}
    >
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        sx={{ border: "1px solid lightgrey" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          sx={draggedOverStyle}
          className={classes.title}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          draggable={isDraggable}
        >
          <span
            className={classes.handle}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onClick={handleDragHandleClick}
          >
            <DragHandleIcon />
          </span>
          <SubchecklistTitle
            title={props.title}
            subchecklistId={idOfFrequencyBox}
            onTitleChange={props.onUpdateTitle}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.controls}>
            <div className={classes.delete}>
              <Button
                variant="contained"
                color="danger"
                size="small"
                onClick={handleDeleteFrequencyBox}
              >
                Delete
              </Button>
            </div>
            <ColorPicker color={props.color} onChange={handleChangeColor} />
          </div>
          <div className={classes.frequencyBox}>
            {items.length > 0 ? (
              items
            ) : (
              <p>There are no frequencies, add a new one to get started...</p>
            )}
          </div>
          <Fab color="primary" aria-label="add" onClick={handleAddFrequency}>
            <AddIcon />
          </Fab>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default FrequencyBox;
