import { Auth } from "@aws-amplify/auth";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

import Card from "../UI/Card";
import AuthContext from "../../store/auth-context";
import classes from "./PasswordResetForm.module.css";
import { showError, showSuccess } from "../../utils/notifications";
import {
  validateConfirmationCode,
  validatePassword,
} from "../../validation/formValidation";
import Footer from "../../layout/Footer/Footer";

const PasswordResetForm = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCodeError, setIsCodeError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const passwordResetDisabled = !(
    process.env.REACT_APP_ENABLE_PASSWORD_RESET === "true"
  );

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleInitiatePasswordReset = async (event) => {
    event.preventDefault();

    // Return if password reset is disabled
    if (passwordResetDisabled) return;

    // Initiate forgot password request
    try {
      setIsLoading(true);
      await Auth.forgotPassword(email);
      setIsLoading(false);
      setIsConfirming(true);
    } catch (err) {
      showError("Error", "Something went wrong");
      console.error(err);
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    // Return if password reset is disabled
    if (passwordResetDisabled) return;

    // Validate confirmation code
    if (!validateConfirmationCode(code)) {
      setCodeError("Invalid code");
      setIsCodeError(true);
      return;
    }

    // Reset code error
    setIsCodeError(false);
    setCodeError("");

    // Check that passwords match
    if (password !== confirmPassword) {
      setNewPasswordError("Passwords do not match!");
      setIsNewPasswordError(true);
      return;
    }

    // Validate password
    const validationResult = validatePassword(password);
    if (!validationResult.valid) {
      setNewPasswordError(validationResult.error);
      setIsNewPasswordError(true);
      return;
    }

    // Reset newPasswordError
    setIsNewPasswordError(false);
    setNewPasswordError("");

    // Change password
    try {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      setIsLoading(false);
      showSuccess("Success", "Password changed successfully!");
      navigate("/login");
    } catch (err) {
      setIsLoading(false);
      if (err.name === "CodeMismatchException") {
        showError("Error", "Invalid code");
      } else if (err.name === "NotAuthorizedException") {
        if (err.message === "Refresh Token has expired") {
          console.log("Refresh token expired, must login again");
          // Set isLoggedIn context to false and redirect to login page
          authCtx.logout();
          navigate("/login");
        }
      } else if (err === "No current user") {
        console.log("Refresh token expired, must login again");
        // Set isLoggedIn context to false and redirect to login page
        authCtx.logout();
        navigate("/login");
      } else if (err.name === "LimitExceededException") {
        showError("Error", "Limit exceeded, please try again after some time.");
      } else {
        showError("Error", "Something went wrong.");
        console.error(err);
      }
    }
  };

  const textFieldStyle = {
    margin: "10px 0",
  };

  const passwordResetDisabledNotice = (
    <p className={classes.passwordDisabled}>
      Password reset is curretly disabled
    </p>
  );

  return (
    <div className={classes.content}>
      <div className={classes.inset}>
        <Card>
          {!isConfirming && (
            <form className={classes["password-reset-form"]}>
              <h1>Password Reset</h1>
              {passwordResetDisabled && passwordResetDisabledNotice}
              <p>
                Enter your email address below to receive instructions on how to
                reset your password.
              </p>
              <div>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  margin="dense"
                  label="Your Email"
                  value={email}
                  onChange={handleEmailChange}
                  sx={textFieldStyle}
                />
              </div>
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                size="small"
                disabled={passwordResetDisabled}
                onClick={handleInitiatePasswordReset}
                loading={isLoading}
              >
                Reset Password
              </LoadingButton>
            </form>
          )}

          {isConfirming && (
            <form className={classes["password-reset-form"]}>
              <h1>Password Reset</h1>
              <p>Enter the confirmation code you received in your email</p>
              <div className={classes.inputs}>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label="Code"
                  value={code}
                  onChange={handleCodeChange}
                  sx={textFieldStyle}
                  error={isCodeError}
                  helperText={codeError}
                />
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  type="password"
                  label="New Password"
                  value={password}
                  onChange={handlePasswordChange}
                  sx={textFieldStyle}
                  error={isNewPasswordError}
                  helperText={newPasswordError}
                />
                <TextField
                  fullWidth
                  type="password"
                  size="small"
                  margin="dense"
                  label="Confirm New Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  sx={textFieldStyle}
                />
              </div>
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                size="small"
                onClick={handleChangePassword}
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </form>
          )}
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordResetForm;
