import { Button } from "@mui/material";

import classes from "./ExampleChecklists.module.css";

const ExampleChecklists = () => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>Example Checklists</p>
      <div className={classes.examples}>
        <div className={classes.example}>
          <p>Normal Procedures</p>

          <a
            href="/examples/normal_procedures.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/thumbnails/seminole_normal_thumbnail.png"
              alt="Example Normal Procedures Checklist"
            />
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="success" size="large">
                Open
              </Button>
            </div>
          </a>
        </div>
        <div className={classes.example}>
          <p>Emergency Procedures</p>
          <a
            href="/examples/emergency_procedures.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/thumbnails/seminole_emergency_thumbnail.png"
              alt="Example Emergency Procedures Checklist"
            />
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="success" size="large">
                Open
              </Button>
            </div>
          </a>
        </div>
      </div>
      <div className={classes.examples}>
        <div className={classes.example}>
          <p>Half Letter Size</p>

          <a
            href="/examples/half_letter_size.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/thumbnails/half_letter_thumbnail.png"
              alt="Half Letter Size"
            />
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="success" size="large">
                Open
              </Button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExampleChecklists;
