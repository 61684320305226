import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";

import classes from "./SuccessPage.module.css";
import { showError } from "../../utils/notifications";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import AuthContext from "../../store/auth-context";

const SuccessPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get("session_id");

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const createPortalSession = () => {
    if (!sessionId) {
      navigate("/pricing");
      return;
    }
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/create-portal-session`;
        const body = {
          session_id: sessionId,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .post(url, body, config)
          .then((res) => {
            if (res.status === 200) {
              // Redirect to portal session url.
              window.location.href = res.data.redirect_url;
            } else {
              console.log(`Unexpected response: ${res}`);
              showError("Error", "Something went wrong.");
              navigate("/myaccount");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
            navigate("/myaccount");
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.inset}>
        <p className={classes.title}>Order Success!</p>
        <img
          className={classes.image}
          src={process.env.PUBLIC_URL + "/images/plane_takeoff.webp"}
          alt="A plane taking off."
        />
        <p className={classes.text}>
          Thank you for supporting Flight Checklists!
        </p>
        <Button variant="contained" onClick={createPortalSession}>
          Manage subscription
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
