// For styling a material-ui Box component.
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

module.exports = { modalStyle };
