import PricingDisplay from "../../components/PricingDisplay/PricingDisplay";
import Footer from "../../layout/Footer/Footer";
import FeedbackBox from "../../components/FeedbackBox/FeedbackBox";
import classes from "./Pricing.module.css";

const Pricing = () => {
  return (
    <div className={classes.container}>
      <PricingDisplay />
      <FeedbackBox />
      <Footer />
    </div>
  );
};

export default Pricing;
