import { Auth } from "aws-amplify";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../store/auth-context";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import { showError } from "../../utils/notifications";
import PaginationTable from "../PaginationTable/PaginationTable";
import classes from "./UserOrders.module.css";

const UserOrders = () => {
  const [orders, setOrders] = useState([]);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/cognito/${user.username}`;
        const config = {
          headers: {
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            if (res.data.Items) {
              setOrders(res.data.Items);
            } else {
              console.error("Unexpected response: ", res);
              showError("Error", "Something went wrong.");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate]);

  const formattedOrders = orders.map((order) => {
    const addressString = `${order.shipping.address.line1}, ${order.shipping.address.city} ${order.shipping.address.state}, ${order.shipping.address.country}, ${order.shipping.address.postal_code}`;
    const total = `$${
      order.amount_total / 100
    } ${order.currency.toUpperCase()}`;
    return {
      ...order,
      amount_total: total,
      shipping: addressString,
    };
  });

  const columns = [
    {
      type: "text",
      name: "Order ID",
      property: "id",
    },
    {
      type: "text",
      name: "Status",
      property: "status",
    },
    {
      type: "text",
      name: "Pages",
      property: "pages",
    },
    {
      type: "text",
      name: "Quantity",
      property: "quantity",
    },
    {
      type: "text",
      name: "Total",
      property: "amount_total",
    },
    {
      type: "text",
      name: "Shipping Address",
      property: "shipping",
    },
    {
      type: "text",
      name: "Order Date",
      property: "createdDate",
    },
  ];

  return (
    <div>
      <p className={classes.title}>Orders</p>
      <PaginationTable columns={columns} data={formattedOrders} />
    </div>
  );
};

export default UserOrders;
