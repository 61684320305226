import { createTheme } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

let theme = createTheme({
  palette: {
    primary: {
      main: "#4f4fee",
      darker: "#1717e8",
      contrastText: "#ffffff",
    },
    secondary: {
      main: green["A200"],
      // darker: green[500],
      contrastText: "#ffffff",
    },
  },
});

theme = createTheme(theme, {
  palette: {
    neutral: {
      ...theme.palette.primary,
    },
    success: {
      ...theme.palette.secondary,
    },
    danger: {
      main: red[500],
      darker: red[900],
      contrastText: "#ffffff",
    },
  },
});

export default theme;
