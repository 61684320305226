import { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Button from "@mui/material/Button";

import classes from "./ColumnBreak.module.css";

const ColumnBreak = memo((props) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  // Handles the mousedown event for the drag handle.
  const handleMouseDownDragHandle = (event) => {
    event.stopPropagation();
    setIsDraggable(true);
  };

  // Handles the mouseup event for the drag handle.
  const handleMouseUpDragHandle = (event) => {
    event.stopPropagation();
    setIsDraggable(false);
  };

  const handleDrag = (event) => {
    event.stopPropagation();
    props.onDrag(props.id);
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    if (!isDraggedOver) {
      setIsDraggedOver(true);
      props.onDragOver(props.id);
    }
  };

  const handleDragLeave = (event) => {
    event.stopPropagation();
    if (isDraggedOver) {
      setIsDraggedOver(false);
    }
  };

  const handleDragEnd = (event) => {
    event.stopPropagation();
    if (isDraggable) {
      setIsDraggable(false);
      props.onDragEnd();
      props.resetDragItemId();
    }
  };

  // Handles the onClick event for the subchecklist drag handle.
  const handleDragHandleClick = (event) => {
    event.stopPropagation();
  };

  const handleDelete = () => {
    props.onDelete(props.id);
  };

  const draggedOverStyle = isDraggedOver ? { background: "lightgrey" } : {};

  return (
    <div>
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        sx={{ border: "1px solid lightgrey" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          sx={draggedOverStyle}
          onDrag={handleDrag}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          draggable={isDraggable}
        >
          <div className={classes.summary}>
            <span
              className={classes.handle}
              onMouseDown={handleMouseDownDragHandle}
              onMouseUp={handleMouseUpDragHandle}
              onClick={handleDragHandleClick}
            >
              <DragHandleIcon />
            </span>
            <span className={classes.title}>Column Break</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.delete}>
            <Button
              variant="contained"
              color="danger"
              size="small"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
          <p>
            This item forces the subsequent content into the next column, or
            page if already in the last column.
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default ColumnBreak;
