import { useContext, useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Card from "../UI/Card";
import classes from "./EmailConfirmationForm.module.css";
import amplify_config from "../../config/aws-amplify-config";
import { showError } from "../../utils/notifications";
import { validateConfirmationCode } from "../../validation/formValidation";
import AuthContext from "../../store/auth-context";
import Footer from "../../layout/Footer/Footer";

Amplify.configure(amplify_config);

const EmailConfirmationForm = () => {
  const [code, setCode] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    try {
      if (!validateConfirmationCode(code)) {
        setError("Please enter a valid code (6 digits).");
        setIsError(true);
        return;
      }
      // Reset error
      setIsError(false);
      setError("");

      setIsLoading(true);

      // Send code to confirm email
      await Auth.confirmSignUp(state.email, code);
      setCode("");

      // Log the user in
      const user = await Auth.signIn(state.email, state.password);
      authCtx.login(user);
      setIsLoading(false);
      navigate("/pricing");
    } catch (err) {
      setIsLoading(false);
      if (err.name === "CodeMismatchException") {
        showError("Error", "Invalid code.");
      } else {
        showError("Error", "Something went wrong.");
        console.error(err);
      }
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.inset}>
        <Card>
          <form className={classes.form}>
            <h1>Confirm your email</h1>
            <p>
              Please enter the code we sent to your email to confirm your
              account
            </p>
            <TextField
              fullWidth
              type="text"
              size="small"
              margin="dense"
              label="Confirmation Code"
              value={code}
              onChange={handleCodeChange}
              error={isError}
              helperText={error}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="success"
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </LoadingButton>
          </form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default EmailConfirmationForm;
