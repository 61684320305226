import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ReactCountryFlag from "react-country-flag";

import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import PdfDisplay from "../PdfDisplay/PdfDisplay";
import AuthContext from "../../store/auth-context";
import classes from "./OrderView.module.css";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { showError } from "../../utils/notifications";
import pricing from "../../config/pricing.json";

const FULL_SIZE_PAGE_LOOKUP_KEY_CAD =
  pricing.orders.pages.full_size.cad.lookup_key;
const FULL_SIZE_PAGE_LOOKUP_KEY_USD =
  pricing.orders.pages.full_size.usd.lookup_key;
const HALF_SIZE_PAGE_LOOKUP_KEY_CAD =
  pricing.orders.pages.half_size.cad.lookup_key;
const HALF_SIZE_PAGE_LOOKUP_KEY_USD =
  pricing.orders.pages.half_size.usd.lookup_key;

const OrderView = (props) => {
  const [numberOfPages, setNumberOfPages] = useState("");
  const [notes, setNotes] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [currency, setCurrency] = useState("CAD");
  const [pageSize, setPageSize] = useState("");

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const disableChecklistOrders = !(
    process.env.REACT_APP_ENABLE_ORDERS === "true"
  );

  const checklistId = props.checklistId;

  // Get checklist data to see page size.
  useEffect(() => {
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${checklistId}`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((data) => {
            if (data.data.error) {
              showError("Error", data.data.error);
              console.log(`Error: ${data.data.error}`);
            } else {
              setPageSize(data.data.Item.pageSize);
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate, checklistId]);

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleGoToCheckout = () => {
    if (numberOfPages === "" || disableChecklistOrders) return;

    // Set lookup_key based on page size and currency.
    let lookup_key = FULL_SIZE_PAGE_LOOKUP_KEY_CAD; // Default value.
    // Page size not yet set
    if (pageSize === "") return;
    // Page size is Letter
    else if (pageSize === "Letter") {
      if (currency === "CAD") lookup_key = FULL_SIZE_PAGE_LOOKUP_KEY_CAD;
      else if (currency === "USD") lookup_key = FULL_SIZE_PAGE_LOOKUP_KEY_USD;
    }
    // Page size is 1/2 Letter
    else if (pageSize === "1/2 Letter") {
      if (currency === "CAD") lookup_key = HALF_SIZE_PAGE_LOOKUP_KEY_CAD;
      else if (currency === "USD") lookup_key = HALF_SIZE_PAGE_LOOKUP_KEY_USD;
    }

    Auth.currentAuthenticatedUser()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/create-checkout-session/payment`;
        const body = {
          lookup_key,
          email: res.attributes.email,
          pages: numberOfPages,
          pageSize,
          quantity,
          notes,
          checklist_id: props.checklistId,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .post(url, body, config)
          .then((res) => {
            if (res.data.redirect_url) {
              window.location.href = res.data.redirect_url;
            } else {
              showError("Error", "Something went wrong.");
              console.log("Unexpected response: ", res);
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const handleNotesChange = (event) => {
    if (event.target.value.length <= 500) {
      setNotes(event.target.value);
    }
  };

  const handleQuantityChange = (event) => {
    if (isNaN(event.target.value)) return; // block anything other than numbers
    if (event.target.value.indexOf(".") !== -1) return; // block decimals
    const newQuantity = Number(event.target.value);
    if (newQuantity < 1 || newQuantity > 100) return;
    setQuantity(newQuantity);
  };

  const checklistOrdersDisabledNotice = (
    <p className={classes.ordersDisabledNotice}>
      Orders are currently disabled
    </p>
  );

  return (
    <div className={classes.container}>
      <div className={classes.controls}>
        <p className={classes.title}>Order Preview</p>
        {disableChecklistOrders && checklistOrdersDisabledNotice}
        <p className={classes.text}>
          Your checklist will be professionally printed and delivered to you!
        </p>
        <p className={classes.text}>
          Double check your checklist and make sure everything is correct. It
          cannot be edited after you order it.
        </p>
        <p className={classes.pageCount}>
          <span className={classes.bold}>Pages: </span>
          {numberOfPages}
        </p>
        <div className={classes.currencySelector}>
          <TextField
            id="outlined-number"
            label="Quantity"
            type="number"
            onChange={handleQuantityChange}
            value={quantity}
            sx={{ width: 120 }}
          />
          <FormControl sx={{ marginLeft: "10px" }}>
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              labelId="currency-label"
              name="currency"
              label="Currency"
              value={currency}
              defaultValue="CAD"
              onChange={handleCurrencyChange}
            >
              <MenuItem value="CAD">
                {<ReactCountryFlag countryCode="CA" />} CAD
              </MenuItem>
              <MenuItem value="USD">
                {<ReactCountryFlag countryCode="US" />} USD
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <TextField
          id="outlined-multiline-static"
          label="Order Notes"
          multiline
          rows={4}
          margin="normal"
          value={notes}
          onChange={handleNotesChange}
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleGoToCheckout}
          disabled={numberOfPages === "" || disableChecklistOrders}
        >
          Checkout
        </Button>
      </div>
      <div className={classes.pdfContainer}>
        <ErrorBoundary>
          <PdfDisplay
            checklistId={props.checklistId}
            setNumberOfPages={setNumberOfPages}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default OrderView;
