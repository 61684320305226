import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { Auth } from "aws-amplify";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import classes from "./PricingDisplay.module.css";
import AuthContext from "../../store/auth-context";
import { showError } from "../../utils/notifications";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import pricing from "../../config/pricing.json";

const PricingDisplay = () => {
  const [currency, setCurrency] = useState("CAD");

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const disableSubscriptions = !(
    process.env.REACT_APP_ENABLE_SUBSCRIPTIONS === "true"
  );

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleCheckout = async (lookup_key) => {
    // If subscriptions are disabled just return.
    if (disableSubscriptions) return;

    Auth.currentAuthenticatedUser()
      .then(async (res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/create-checkout-session/subscription`;
        const body = {
          lookup_key,
          email: res.attributes.email,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .post(url, body, config)
          .then((res) => {
            if (res.data.redirect_url) {
              // Redirect to checkout url
              window.location.href = res.data.redirect_url;
            } else {
              console.error("Unexpected response: ", res);
              showError("Error", "Something went wrong.");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  let hangarPlanLookupKey;
  let fleetPlanLookupKey;
  let hangarPlanPrice;
  let fleetPlanPrice;
  let fullSizePagePrice;
  let halfSizePagePrice;

  if (currency === "CAD") {
    hangarPlanLookupKey = pricing.subscriptions.hangar.cad.lookup_key;
    fleetPlanLookupKey = pricing.subscriptions.fleet.cad.lookup_key;
    hangarPlanPrice = pricing.subscriptions.hangar.cad.price;
    fleetPlanPrice = pricing.subscriptions.fleet.cad.price;
    fullSizePagePrice = pricing.orders.pages.full_size.cad.price;
    halfSizePagePrice = pricing.orders.pages.half_size.cad.price;
  } else {
    hangarPlanLookupKey = pricing.subscriptions.hangar.usd.lookup_key;
    fleetPlanLookupKey = pricing.subscriptions.fleet.usd.lookup_key;
    hangarPlanPrice = pricing.subscriptions.hangar.usd.price;
    fleetPlanPrice = pricing.subscriptions.fleet.usd.price;
    fullSizePagePrice = pricing.orders.pages.full_size.usd.price;
    halfSizePagePrice = pricing.orders.pages.half_size.usd.price;
  }

  const subscriptionsDisabledNotice = (
    <p className={classes.subscriptionsDisabled}>
      Subscriptions are currently disabled
    </p>
  );

  return (
    <div className={classes.container}>
      <p className={classes.title}>Pricing</p>
      <div className={classes.currencySelector}>
        <FormControl>
          <InputLabel id="currency-label">Currency</InputLabel>
          <Select
            labelId="currency-label"
            name="currency"
            label="Currency"
            value={currency}
            defaultValue="CAD"
            onChange={handleCurrencyChange}
          >
            <MenuItem value="CAD">
              {<ReactCountryFlag countryCode="CA" />} CAD
            </MenuItem>
            <MenuItem value="USD">
              {<ReactCountryFlag countryCode="US" />} USD
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {disableSubscriptions && subscriptionsDisabledNotice}
      <p className={classes.subtitle}>Plans</p>
      <div className={classes.plans}>
        <div className={classes.plan}>
          <p className={classes.planTitle}>Basic</p>
          <ul>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;1 Checklist</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;300 Checklist Items</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;1 Checklist Style</span>
            </li>
            <li>
              <ClearIcon color="warning" />
              <span>&nbsp;Upload your own logo</span>
            </li>
          </ul>
          <p className={classes.price}>Free</p>
        </div>
        <div className={classes.plan}>
          <p className={classes.planTitle}>Hangar</p>
          <ul>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;3 Checklists</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;300 Checklist Items each</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;2 Checklist Styles</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Upload your own logo</span>
            </li>
          </ul>
          <p className={classes.price}>${hangarPlanPrice}/month</p>
          <Button
            variant="contained"
            color="neutral"
            disabled={disableSubscriptions}
            onClick={() => handleCheckout(hangarPlanLookupKey)}
            fullWidth
          >
            Buy Hangar
          </Button>
        </div>
        <div className={classes.plan}>
          <p className={classes.planTitle}>Fleet</p>
          <ul>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;10 Checklists</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;300 Checklist Items each</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;2 Checklist Styles</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Upload your own logo</span>
            </li>
          </ul>
          <p className={classes.price}>${fleetPlanPrice}/month</p>
          <Button
            variant="contained"
            color="neutral"
            disabled={disableSubscriptions}
            onClick={() => handleCheckout(fleetPlanLookupKey)}
            fullWidth
          >
            Buy Fleet
          </Button>
        </div>
      </div>
      <p className={classes.subtitle}>Orders</p>
      <p className={classes.description}>
        Your checklist professionally printed, laminated and shipped to you!
      </p>
      <div className={classes.pages}>
        <div className={classes.page}>
          <p className={classes.planTitle}>Full-Size Page</p>
          <ul>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Letter (8.5" x 11")</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Double sided</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;High Quality</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Laminated</span>
            </li>
          </ul>
          <p className={classes.price}>${fullSizePagePrice}/page</p>
        </div>
        <div className={classes.page}>
          <p className={classes.planTitle}>Half-Size Page</p>
          <ul>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;1/2 Letter (5.5" x 8.5")</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Double sided</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;High Quality</span>
            </li>
            <li>
              <CheckIcon color="success" />
              <span>&nbsp;Laminated</span>
            </li>
          </ul>
          <p className={classes.price}>${halfSizePagePrice}/page</p>
        </div>
      </div>
    </div>
  );
};

export default PricingDisplay;
