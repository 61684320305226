import { useState, useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Auth } from "aws-amplify";
import ScaleLoader from "react-spinners/ScaleLoader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classes from "./SubscriptionTimeline.module.css";
import AuthContext from "../../store/auth-context";
import { handleAuthError } from "../../utils/errorHandlers";

const SubscriptionTimeline = () => {
  const [timeline, setTimeline] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/subscriptions/history`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((data) => {
            setIsLoading(false);
            if (data.data.error) {
              console.log(data.data.error);
            } else {
              setTimeline(data.data.timeline);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            // handleAxiosError(err);
            console.error("Error: ", err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate]);

  const getPlanName = (lookup_key) => {
    if (
      lookup_key.includes("basic_plan_cad") ||
      lookup_key.includes("basic_plan_usd")
    ) {
      return "Basic Plan";
    } else if (
      lookup_key.includes("hangar_plan_cad") ||
      lookup_key.includes("hangar_plan_usd")
    ) {
      return "Hangar Plan";
    } else if (
      lookup_key.includes("fleet_plan_cad") ||
      lookup_key.includes("fleet_plan_usd")
    ) {
      return "Fleet Plan";
    }
  };

  const timelineItems = timeline.map((item) => {
    const startDate = new Date(item.start_date * 1000);
    const endDate = new Date(item.end_date * 1000);

    return (
      <TableRow key={item.start_date}>
        <TableCell>{getPlanName(item.name)}</TableCell>
        <TableCell>
          {item.unit_amount / 100} {item.currency.toUpperCase()}
        </TableCell>
        <TableCell>{startDate.toDateString()}</TableCell>
        <TableCell>{endDate.toDateString()}</TableCell>
      </TableRow>
    );
  });

  const table = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Plan</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{timelineItems}</TableBody>
      </Table>
    </TableContainer>
  );
  let content;
  if (isLoading) {
    content = (
      <div className={classes.spinner}>
        <ScaleLoader color="#4f4fee" />
      </div>
    );
  } else if (timeline.length === 0) {
    content = (
      <p className={classes.timelineMessage}>
        There are no timeline items to display...
      </p>
    );
  } else {
    content = table;
  }

  return (
    <Fragment>
      <p className={classes.title}>Timeline</p>
      {content}
    </Fragment>
  );
};

export default SubscriptionTimeline;
