import { useState } from "react";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";

import { showError, showSuccess } from "../../../utils/notifications";
import { validateEmail } from "../../../validation/formValidation";

const FeedbackBoxPopup = (props) => {
  const [email, setEmail] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [isEmailBodyError, setIsEmailBodyError] = useState(false);
  const [emailBodyErrorText, setEmailBodyErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const open = props.open;
  const handleClose = props.onClose;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailBodyChange = (event) => {
    setEmailBody(event.target.value);
  };

  const handleCancel = () => {
    handleClose();
    setEmail("");
    setEmailBody("");
    setIsEmailError(false);
    setIsEmailBodyError(false);
    setEmailErrorText("");
    setEmailBodyErrorText("");
  };

  const sendEmail = () => {
    if (!validateEmail(email)) {
      setEmailErrorText("Must be a valid email");
      setIsEmailError(true);
    }
    if (emailBody.length < 10) {
      setEmailBodyErrorText("Must be at least 10 characters");
      setIsEmailBodyError(true);
    }
    // Send email
    setIsLoading(true);
    const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/feedback`;
    const body = {
      email,
      body: emailBody,
    };
    axios
      .post(url, body, {})
      .then((res) => {
        setIsLoading(false);
        handleCancel();
        showSuccess(
          "Feedback submitted!",
          "Your feedback has been sent. Thank you!"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        showError("Error", "Feedback data is invalid.");
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Send Feedback!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Think you know something that would make this site better? Have you
          found a bug? Let us know and we will do our best to add it! Be nice!
          😃
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          sx={{ marginY: "15px" }}
          fullWidth
          onChange={handleEmailChange}
          error={isEmailError}
          helperText={emailErrorText}
        />
        <TextField
          id="outlined-textarea"
          label="Feedback"
          placeholder="Request here..."
          fullWidth
          multiline
          minRows={3}
          onChange={handleEmailBodyChange}
          error={isEmailBodyError}
          helperText={emailBodyErrorText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          variant="contained"
          onClick={sendEmail}
          loading={isLoading}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackBoxPopup;
