import { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

import { showSuccess, showError } from "../../utils/notifications";
import { validatePassword } from "../../validation/formValidation";
import Card from "../UI/Card";
import classes from "./ChangePasswordForm.module.css";

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async (event) => {
    event.preventDefault();

    const validationResponse = validatePassword(newPassword);

    if (currentPassword.length === 0) {
      setPasswordError("Cannot be empty.");
      setIsPasswordError(true);
      return;
    } else if (!validationResponse.valid) {
      // Reset password error
      setIsPasswordError(false);
      setPasswordError("");

      // Set newPasswordError
      setNewPasswordError(validationResponse.error);
      setIsNewPasswordError(true);
      return;
    } else if (newPassword !== confirmNewPassword) {
      // Reset password error
      setIsPasswordError(false);
      setPasswordError("");

      // Set newPasswordError
      setNewPasswordError(
        "New password and confirmation password are different."
      );
      setIsNewPasswordError(true);
      return;
    }

    // Reset all errors
    setIsPasswordError(false);
    setPasswordError("");
    setIsNewPasswordError(false);
    setNewPasswordError("");

    try {
      setIsLoading(true);
      // Get current user
      const currentUser = await Auth.currentUserPoolUser();

      // Change password
      await Auth.changePassword(currentUser, currentPassword, newPassword);
      setIsLoading(false);
      showSuccess("Success", "Password changed successfully.");
    } catch (err) {
      setIsLoading(false);
      if (err.name === "NotAuthorizedException") {
        showError("Error", "Incorrect password");
      } else showError("Error", `Something went wrong: ${err.message}}`);
    }

    // Reset form
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  return (
    <div className={classes.content}>
      <div className={classes.inset}>
        <Card>
          <form className={classes.form}>
            <h1>Change Password</h1>
            <TextField
              fullWidth
              size="small"
              margin="dense"
              type="password"
              label="Current Password"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
              error={isPasswordError}
              helperText={passwordError}
            />
            <TextField
              fullWidth
              size="small"
              margin="dense"
              type="password"
              label="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              error={isNewPasswordError}
              helperText={newPasswordError}
            />
            <TextField
              fullWidth
              size="small"
              margin="dense"
              type="password"
              label="Confirm New Password"
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="success"
              onClick={handleChangePassword}
              loading={isLoading}
            >
              Confirm
            </LoadingButton>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
