import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

import Card from "../../components/UI/Card";
import Image from "../../components/Image/Image";
import FeedbackBox from "../../components/FeedbackBox/FeedbackBox";
import classes from "./Guide.module.css";

const Guide = () => {
  const navigate = useNavigate();

  const redirectHandler = (uri) => {
    navigate(uri);
  };

  return (
    <div className={classes.container}>
      <div className={classes.inset}>
        <Card>
          <div className={classes.content}>
            <div className={classes.section}>
              <h1>Guide</h1>
              <div className={classes.subsection}>
                <p className={classes.intro}>
                  Here is a small guide on how to create a checklist with Flight
                  Checklists.
                </p>
                <p>
                  <span
                    className={classes.pageLink}
                    onClick={() => {
                      redirectHandler("/login");
                    }}
                  >
                    Log in
                  </span>{" "}
                  to your Flight Checklists account. If you don't already have
                  an account,{" "}
                  <span
                    className={classes.pageLink}
                    onClick={() => redirectHandler("/signup")}
                  >
                    sign up here
                  </span>
                  .
                </p>
                <p>
                  Once logged in, click the Checklists tab in the top right
                  section of the page. You should now see a button that says
                  "NEW CHECKLIST".
                </p>
                <Image
                  src="images/guide/new_checklist_button.png"
                  alt="Screenshot of Checklists page."
                />
                <p>
                  Click this button, and write the name you want for your
                  checklist in the input once it appears. You can also select a
                  checklist template to start from if you choose. Finally click
                  the "SUBMIT" button to create the checklist.
                </p>
                <Image
                  src="images/guide/new_checklist_name_input.png"
                  maxWidth="50%"
                  alt="Screenshot of new checklist form."
                />
                <p>
                  After clicking submit, it should take you to the Checklist
                  Editor page for your new checklist.
                </p>
                <Image
                  src="images/guide/checklist_editor_page.png"
                  alt="Checklist editor page."
                  maxWidth="100%"
                />
                <p>
                  Initially your checklist will be empty. You can add items with
                  the plus button.
                </p>
                <Image
                  src="images/guide/plus_button.png"
                  alt="Add checklist button."
                  maxWidth="10%"
                />
              </div>
            </div>

            <div className={classes.section}>
              <h1>Checklist items</h1>
              <p>Here are the different items you can add to your checklist.</p>
              <ul>
                <li>Subchecklist</li>
                <li>Frequency Box</li>
                <li>Section Title</li>
                <li>Text Box</li>
                <li>Blank Space</li>
              </ul>
              <div className={classes.subsection}>
                <h2>Subchecklist</h2>
                <p>
                  A subchecklist is a collection of related check items,
                  preconditions, and postconditions. An example of a
                  subchecklist would be the "Engine Fire at Startup"
                  subchecklist in an aircraft emergency checklist.
                </p>
                <Image
                  src="images/guide/subchecklist.png"
                  maxWidth="50%"
                  alt="Subchecklist item."
                />
                <div className={classes.subSubsection}>
                  <h3>Check Item</h3>
                  <p>
                    A check item is something you can add to a subchecklist.
                    This will be the most common item in a subchecklist. A check
                    item has two parts, the item and the action. An example
                    check item in the above picture of the "Engine Fire at
                    Startup" subchecklist is "Mixture..........ICO".
                  </p>
                </div>
                <div className={classes.subSubsection}>
                  <h3>Precondition</h3>
                  <p>
                    A precondition is something you can add to a subchecklist. A
                    precondition describes a condition which should be met
                    before the following check item is performed. An example
                    precondition in the above "Engine Fire at Startup"
                    subchecklist is "If Engine Starts".
                  </p>
                </div>
                <div className={classes.subSubsection}>
                  <h3>Postcondition</h3>
                  <p>
                    A postcondition is something you can add to a subchecklist.
                    A postcondition is used to describe a condition which should
                    be met after a check item is performed. An example
                    postcondition in the above "Engine Fire at Startup"
                    subchecklist is "(to pull flames into engine)".
                  </p>
                </div>
              </div>
              <div className={classes.subsection}>
                <h2>Frequency Box</h2>
                <p>
                  A Frequency Box is a collection of frequencies. Each frequency
                  has a name and a frequency. As you can see, this could also be
                  used to hold an important phone number.
                </p>
                <Image
                  src="images/guide/frequency_box.png"
                  maxWidth="50%"
                  alt="Frequency box item."
                />
              </div>
              <div className={classes.subsection}>
                <h2>Section Title</h2>
                <p>
                  A Section Title is designed to prefix more than one related
                  subchecklists. For example, the "Startup" Section Title below
                  might have the following Subchecklists under it; "Pre-Start",
                  "Engine Start #1 (LEFT)", "Engine Start #2 (RIGHT)", and
                  "After Start".
                </p>
                <Image
                  src="images/guide/section_title.png"
                  maxWidth="50%"
                  alt="Section title item."
                />
              </div>
              <div className={classes.subsection}>
                <h2>Text Box</h2>
                <p>
                  A Text Box allows you to put formatted text in your checklist.
                  It uses Markdown language for formatting. These work well for
                  things like pre-takeoff briefing notes.
                </p>
                <Image
                  src="images/guide/text_box.png"
                  maxWidth="50%"
                  alt="Text box item."
                />
              </div>
              <div className={classes.subsection}>
                <h2>Blank Space</h2>
                <p>
                  Blank Space allows you to put{" "}
                  <span className={classes.italic}>blank space</span> in between
                  your checklist items. Without blank space, items are
                  automatically positioned right after the previous as long as
                  there is room remaining in the column/page. Blank space can be
                  used just to give some separation between two subchecklists,
                  or can be used to push an item to the next column or page.
                  Below is an image of two subchecklists with a blank space item
                  in between them.
                </p>
                <Image
                  src="images/guide/blank_space.png"
                  maxWidth="50%"
                  maxHeight="50%"
                  alt="Blank space item."
                />
              </div>
              <div className={classes.subsection}>
                <h2>Column Break</h2>
                <p>
                  A Column Break will put subsequent content into the next
                  column. If the content is already in the last column, the
                  Column Break will put subsequent content onto the next page.
                </p>
                <p>
                  Here is an example showing the use of a Column Break. The
                  first image shows two Subchecklists without a Column Break,
                  and the second image shows the same two Subchecklists with a
                  Column Break in between.
                </p>
                <p className={classes.italic}>Without Column Break:</p>
                <Image
                  src="images/guide/without_column_break.png"
                  maxWidth="50%"
                  maxHeight="50%"
                  alt="Without Column Break item."
                />
                <p className={classes.italic}>With Column Break:</p>
                <Image
                  src="images/guide/with_column_break.png"
                  maxWidth="100%"
                  maxHeight="50%"
                  alt="With Column Break item."
                />
              </div>
            </div>
            <div className={classes.section}>
              <h1>Editor Controls</h1>
              <p>
                The following editor controls are available at the top of the
                Checklist Editor page.
              </p>
              <ul>
                <li>Settings</li>
                <ul>
                  <li>Checklist Style</li>
                  <li>Page Size</li>
                  <li>Columns</li>
                  <li>Font Size</li>
                  <li>Font Family</li>
                </ul>
                <li>Logo</li>
                <li>Save</li>
                <li>Download</li>
              </ul>
              <Image
                src="images/guide/editor_controls.png"
                maxWidth="50%"
                alt="Checklist editor controls."
              />
              <div className={classes.subsection}>
                <h2 className={classes.iconTitle}>
                  Settings&nbsp;
                  <SettingsIcon />
                </h2>
                <p>
                  Opens the Settings modal, allowing you to change your
                  Checklist Style, Page Size, Columns, Font-Size, and
                  Font-Family.
                </p>
                <Image src="images/guide/controls_modal.png" maxWidth="50%" />
              </div>
              <div className={classes.subsection}>
                <h2>Checklist Style</h2>
                <p>
                  Changes the style of your checklist. There are currently two
                  styles; "Dots" and "Table".
                </p>
                <h3>Dots Style</h3>
                <Image src="images/guide/dots_style.png" maxWidth="50%" />
                <h3>Table Style</h3>
                <Image src="images/guide/table_style.png" maxWidth="50%" />
              </div>
              <div className={classes.subsection}>
                <h2>Page Size</h2>
                <p>
                  Changes the page size of your checklist. The available options
                  are Letter size (8.5"x11") and Half-Letter size (5.5"x11").
                </p>
              </div>
              <div className={classes.subsection}>
                <h2>Columns</h2>
                <p>Changes the number of columns in your checklist.</p>
              </div>
              <div className={classes.subsection}>
                <h2>Font Size</h2>
                <p>Changes the font size in your checklist.</p>
              </div>
              <div className={classes.subsection}>
                <h2>Font Family</h2>
                <p>Changes the font family in your checklist.</p>
              </div>
              <div className={classes.subsection}>
                <h2>Logo</h2>
                <p>
                  Allows you to upload a logo for your checklist (available only
                  on Hangar and Fleet plans).
                </p>
              </div>
              <div className={classes.subsection}>
                <h2>Save</h2>
                <p>
                  Saves your checklist. Once saved, the PDF display on the right
                  will update with your new checklist data.
                </p>
              </div>
              <div className={classes.subsection}>
                <h2>Download</h2>
                <p>Downloads your checklist as a PDF file.</p>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <FeedbackBox />
    </div>
  );
};

export default Guide;
