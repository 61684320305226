import { showError } from "./notifications";

// Handles auth error for Auth.currentSession() Amplify function. (Maybe Auth.currentAuthenticatedUser() also).
const handleAuthError = (err, navigateFunction, authContext) => {
  if (err.name === "NotAuthorizedException") {
    if (err.message === "Refresh Token has expired") {
      console.log("Refresh token expired, must login again");
      // Set isLoggedIn context to false and redirect to login page
      authContext.logout();
      navigateFunction("/login");
    } else {
      console.error("Not Authorized");
      console.error(err);
    }
  } else if (err === "No current user" || "The user is not authenticated") {
    console.log("Refresh token expired, must login again");
    // Set isLoggedIn context to false and redirect to login page
    authContext.logout();
    navigateFunction("/login");
  } else {
    showError("Error", err.response.data.message);
    console.error(err);
  }
};

const handleAxiosError = (err) => {
  console.error("Axios error: ", err);

  // Check if a response body exists with an error message.
  const errorResponse = err.response;
  if (
    typeof errorResponse !== "undefined" &&
    typeof errorResponse.data.error !== "undefined"
  ) {
    showError("Error", errorResponse.data.error);
  } else {
    showError("Error", "Something went wrong.");
  }
};

export { handleAuthError, handleAxiosError };
