import Card from "../../components/UI/Card";

const NotFound = () => {
  console.log("Not found!");
  return (
    <Card>
      <h1>Page not found!</h1>
      <p>Sorry, that page does not exist!</p>
    </Card>
  );
};

export default NotFound;
