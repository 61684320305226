import { NavLink } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.container}>
      <hr className={classes.topHR} />
      <div className={classes.content}>
        <div className={classes.columns}>
          <div className={classes.column}>
            <p>
              <NavLink to="/">Home</NavLink>
            </p>
            <p>
              <NavLink to="/login">Login</NavLink>
            </p>
            <p>
              <NavLink to="/signup">Signup</NavLink>
            </p>
          </div>
          <div className={classes.column}>
            <p>
              <a
                className={classes.iconItem}
                href="https://www.youtube.com/channel/UCoDFg63s1-xWVZBRqHDpEDw"
              >
                <YouTubeIcon />
                &nbsp;Youtube
              </a>
            </p>
            <p>
              <a
                className={classes.iconItem}
                href="https://www.instagram.com/flightchecklists"
              >
                <InstagramIcon />
                &nbsp;Instagram
              </a>
            </p>
            <p>info@flightchecklists.com</p>
          </div>
        </div>
        <p className={classes.copyright}>
          &#169; 2023 FlightChecklists Software Ltd. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
