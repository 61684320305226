import classes from "./YoutubePlayer.module.css";

const YoutubePlayer = (props) => {
  const embedId = props.embedId;

  return (
    <div className={classes.container}>
      <iframe
        className={classes.frame}
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubePlayer;
