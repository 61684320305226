import classes from "./Image.module.css";

const Image = (props) => {
  const imageStyle = {
    maxWidth: props.maxWidth || "100%",
    maxHeight: props.maxHeight || "100%",
  };
  return (
    <img
      className={classes.image}
      style={imageStyle}
      src={props.src}
      alt={props.alt}
    />
  );
};

export default Image;
