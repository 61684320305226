import { Auth } from "aws-amplify";
import { useState, useRef, useEffect, useContext, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Button from "@mui/material/Button";
import ScaleLoader from "react-spinners/ScaleLoader";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import AuthContext from "../../store/auth-context";
import classes from "./PdfDisplay.module.css";
import { handleAuthError } from "../../utils/errorHandlers";

const PdfDisplay = memo((props) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(0);
  const [fileObj, setFileObj] = useState({ url: "" });
  const [showControls, setShowControls] = useState(true);
  const containerRef = useRef();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  // Destructure props for useEffect
  const alert = props.alert;
  const checklistId = props.checklistId;
  const orderId = props.orderId;
  const setAlert = props.setAlert;

  let controlsTimer;

  // Load the pdf file
  useEffect(() => {
    const loadPdf = async () => {
      try {
        if (alert === true) setAlert(false); // Reset alert
        const session = await Auth.currentSession();

        // Set url based on whether checklistId or orderId was supplied in props
        const url = checklistId
          ? `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${checklistId}/pdf`
          : `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/orders/${orderId}/pdf`;
        const newFileObj = {
          url,
          httpHeaders: {
            Authorization: `Bearer ${session.accessToken.jwtToken}`,
            Accept: "application/pdf",
          },
        };
        setFileObj(newFileObj);
      } catch (err) {
        handleAuthError(err, navigate, authCtx);
      }
    };
    loadPdf();
  }, [alert, setAlert, checklistId, orderId, authCtx, navigate]);

  /**
   * Once the pdf loads, this function sets the state for number of pages and the width.
   * @param {object} pdf
   */
  const handleDocumentLoaded = (pdf) => {
    setNumberOfPages(pdf.numPages);
    setWidth(containerRef.current.offsetWidth);

    // Output number of pages to display on order preview page
    if (props.setNumberOfPages) props.setNumberOfPages(pdf.numPages);

    // Set timer to hide controls
    if (!showControls) setShowControls(true);
    controlsTimer = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  // Handles the event from clicking the zoom in button
  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  // Handles the event from clicking the zoom out button
  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const handleMouseEnterControls = (event) => {
    clearTimeout(controlsTimer);
    setShowControls(true);
  };

  const handleMouseLeaveControls = (event) => {
    controlsTimer = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  // Maps the number of pages into an array of Page elements to be displayed
  const getPageElements = () => {
    return [...Array(numberOfPages).keys()].map((pageNum) => (
      <Page
        className={classes.page}
        key={pageNum + 1}
        pageNumber={pageNum + 1}
        width={width * 0.9}
        scale={scale}
      />
    ));
  };

  const loadScreen = (
    <div className={classes.loading}>
      <ScaleLoader color="#4f4fee" />
    </div>
  );

  const showZoomControls = props.showZoomControls ? true : false;

  return (
    <div className={classes.container} ref={containerRef}>
      {showZoomControls && (
        <div
          className={
            showControls ? classes.zoomControls : classes.zoomControlsHidden
          }
          onMouseEnter={handleMouseEnterControls}
          onMouseLeave={handleMouseLeaveControls}
        >
          <Button
            variant="contained"
            color="neutral"
            size="small"
            onClick={handleZoomOut}
          >
            <ZoomOutIcon fontSize="large" />
          </Button>
          <Button
            variant="contained"
            color="neutral"
            size="small"
            onClick={handleZoomIn}
          >
            <ZoomInIcon fontSize="large" />
          </Button>
        </div>
      )}
      <Document
        className={classes.document}
        file={fileObj}
        loading={loadScreen}
        onLoadSuccess={handleDocumentLoaded}
      >
        {getPageElements()}
      </Document>
    </div>
  );
});

export default PdfDisplay;
