import React from "react";
import axios from "axios";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { handleAxiosError } from "../utils/errorHandlers";

const AuthContext = React.createContext({
  isLoggedIn: false,
  isAdmin: false,
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", false);
  const [isAdmin, setIsAdmin] = useLocalStorage("isAdmin", false);

  const loginHandler = async (user) => {
    if (!isLoggedIn) setIsLoggedIn(true);

    // Check if user is admin
    const userAccessToken = user.signInUserSession.accessToken.jwtToken;
    const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/isadmin`;
    const config = {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
      },
    };
    try {
      const res = await axios.get(url, config);
      if (res.data.isAdmin) {
        setIsAdmin(true);
      }
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const logoutHandler = () => {
    if (isLoggedIn) setIsLoggedIn(false);
    if (isAdmin) setIsAdmin(false);
  };

  const contextValue = {
    isLoggedIn: isLoggedIn,
    isAdmin: isAdmin,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
