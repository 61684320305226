import { Auth } from "aws-amplify";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import AuthContext from "../../store/auth-context";
import classes from "./AccountDetails.module.css";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";

const AccountDetails = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setEmail(res.attributes.email);
        setUsername(res.username);
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  });

  const createPortalSession = () => {
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/create-portal-session`;
        const body = {};
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .post(url, body, config)
          .then((res) => {
            if (res.data.redirect_url) {
              // Redirect to portal session
              window.location.href = res.data.redirect_url;
            } else {
              navigate("/pricing");
            }
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const goToSubscriptionPage = () => {
    navigate("/subscription");
  };

  return (
    <div className={classes.container}>
      <h1>My Account</h1>
      <div>
        <span>Email: </span>
        {email}
      </div>
      <div>
        <span>User ID: </span>
        {username}
      </div>
      <div>
        <p className={classes.billingLink} onClick={createPortalSession}>
          Billing
        </p>
        <p className={classes.subscriptionLink} onClick={goToSubscriptionPage}>
          Subscription
        </p>
      </div>
    </div>
  );
};

export default AccountDetails;
