import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import classes from "./AdminPage.module.css";

const AdminPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.link}>
        <NavLink to="/admin/users">
          <Button variant="contained" color="neutral">
            Users
          </Button>
        </NavLink>
      </div>
      <div className={classes.link}>
        <NavLink to="/admin/checklists">
          <Button variant="contained" color="neutral">
            Checklists
          </Button>
        </NavLink>
      </div>
      <div className={classes.link}>
        <NavLink to="/admin/paymentusers">
          <Button variant="contained" color="neutral">
            PaymentUsers
          </Button>
        </NavLink>
      </div>
      <div className={classes.link}>
        <NavLink to="/admin/orders">
          <Button variant="contained" color="neutral">
            Orders
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default AdminPage;
