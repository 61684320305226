import { NavLink } from "react-router-dom";
import { Button, TextField, TablePagination } from "@mui/material";
import { Fragment, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

import classes from "./PaginationTable.module.css";

const PaginationTable = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const data = props.data;
  const keyProperty = props.keyProperty;
  const columns = props.columns;

  // Expect an array of objects with following properties
  // type - Either "link", "text", or "action"
  // name - To be displayed in column header
  // property - property name in the object data.
  // baseUrl - base url path to item description page (i.e. "/checklists") (only required if type is link)
  // color - Either "neutral", "success", or "danger" (only required if type is action)
  // onClick - The function to run when the action button is clicked (only required if type is action)
  // actionParameters - the parameters for the onClick handler (only required if type is action)
  // actionName - The text on the action button

  const handleSearchTermChange = (event) => {
    setCurrentPage(0);
    setSearchTerm(event.target.value);
  };

  const stringIsQuoted = (string) => {
    if (
      string.length > 1 &&
      string[0] === '"' &&
      string[string.length - 1] === '"'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const matchesSearchTerm = (item) => {
    for (let i = 0; i < columns.length; i++) {
      // Not an action column, and searchterm isnt quoted, and it exists somewhere in the property string.
      if (
        columns[i].type !== "action" &&
        !stringIsQuoted(searchTerm) &&
        item[columns[i].property].indexOf(searchTerm) !== -1
      ) {
        return true;
      }
      // Not an action column, search term is quoted.
      else if (columns[i].type !== "action" && stringIsQuoted(searchTerm)) {
        const searchTermUnquoted = searchTerm.slice(1, searchTerm.length - 1);
        // If property string is exactly the same as searchterm.
        if (item[columns[i].property] === searchTermUnquoted) {
          return true;
        }
      }
    }
    return false;
  };

  const getPaginatedData = (allData) => {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    return allData.slice(start, end);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getOnClickHandler = (dataObject, parameterArray, handlerFunction) => {
    let parameters = [];
    parameterArray.forEach((parameter) => {
      parameters.push(dataObject[parameter]);
    });
    return () => {
      handlerFunction(...parameters);
    };
  };

  const columnsHeaderRow = columns.map((column) => (
    <td className={classes.tableData}>{column.name}</td>
  ));

  let dataRows = data.map((item) => {
    if (searchTerm === "" || matchesSearchTerm(item)) {
      const cells = columns.map((column, index) => {
        if (column.type === "link") {
          return (
            <td className={classes.tableData}>
              <NavLink to={`${column.baseUrl}/${item.id}`}>
                {item[column.property]}
              </NavLink>
            </td>
          );
        } else if (column.type === "action") {
          return (
            <td className={classes.tableData}>
              <Button
                variant="contained"
                color={column.color}
                size="small"
                onClick={getOnClickHandler(
                  item,
                  column.actionParameters,
                  column.onClick
                )}
              >
                {column.actionName}
              </Button>
            </td>
          );
        } else if (column.type === "text") {
          return <td className={classes.tableData}>{item[column.property]}</td>;
        } else {
          return <td className={classes.tableData}>{item[column.property]}</td>;
        }
      });

      return <tr key={item[keyProperty]}>{cells}</tr>;
    } else {
      return null;
    }
  });

  dataRows = dataRows.filter((item) => (item === null ? false : true));

  const pagination = (
    <TablePagination
      count={dataRows.length}
      page={currentPage}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      color="neutral"
    />
  );

  const loadingSpinner = (
    <div>
      <ScaleLoader color="#4f4fee" />
    </div>
  );

  const table = (
    <Fragment>
      <TextField
        type="text"
        size="small"
        margin="dense"
        label="Search"
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>{columnsHeaderRow}</tr>
        </thead>
        <tbody>{getPaginatedData(dataRows)}</tbody>
      </table>
      <div className={classes.pagination}>{pagination}</div>
    </Fragment>
  );

  if (props.loading) {
    return loadingSpinner;
  } else {
    if (data.length > 0) {
      return table;
    } else {
      return <p>No data to show...</p>;
    }
  }
};

export default PaginationTable;
