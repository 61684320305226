import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Button, Box, Modal } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import classes from "./AdminChecklistsPage.module.css";
import AuthContext from "../../store/auth-context";
import StyledLink from "../../components/StyledLink/StyledLink";
import { modalStyle } from "../../utils/modalStyle";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const AdminChecklistsPage = () => {
  const [checklists, setChecklists] = useState([]);
  const [alert, setAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idOfChecklistToDelete, setIdOfChecklistToDelete] = useState("");
  const [nameOfChecklistToDelete, setNameOfChecklistToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState("");

  // Pagination state
  const [count, setCount] = useState(0);
  const [approximateTotalCount, setApproximateTotalCount] = useState(0);
  const [paginationToken, setPaginationToken] = useState("");
  const [currPaginationToken, setCurrPaginationToken] = useState("");
  const [prevPaginationTokens, setPrevPaginationTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setIsLoading(true);
        setAlert(false);
        let url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/all`;

        // Add paginationToken if we have one.
        if (currPaginationToken !== "" && createdBy === "") {
          url += `?lastkey=${encodeURIComponent(currPaginationToken)}`;
        }
        // Set createdby if it's used
        if (createdBy !== "") {
          url += `?createdby=${createdBy}`;
          resetPagingState();
        }

        const config = {
          headers: {
            Authorization: `Bearer ${res.signInUserSession.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            setChecklists(res.data.Items);
            setCount(res.data.Count);
            setApproximateTotalCount(res.data.ApproximateTotalCount);
            setPaginationToken(
              res.data.LastEvaluatedKey ? res.data.LastEvaluatedKey.id : ""
            );
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [alert, authCtx, navigate, currPaginationToken]);

  const getShortDateString = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toDateString();
  };

  const triggerRender = () => {
    setAlert(true);
  };

  const handleDeleteChecklist = async () => {
    // This case shouldnt happen, because the id is set when the delete button is pressed.
    if (idOfChecklistToDelete === "") {
      console.log("No checklist to delete");
      return;
    } else if (!showModal) {
      console.log("Cannot delete checklist, modal not open");
      return;
    }
    const id = idOfChecklistToDelete;

    try {
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/checklists/${id}`;
      const session = await Auth.currentSession();
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      axios
        .delete(url, config)
        .then((res) => {
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          triggerRender();
        })
        .catch((err) => {
          setShowModal(false);
          setIdOfChecklistToDelete("");
          setNameOfChecklistToDelete("");
          handleAxiosError(err);
        });
    } catch (err) {
      handleAuthError(err, navigate, authCtx);
    }
  };

  const handleClickDelete = (id, name) => {
    setIdOfChecklistToDelete(id);
    setNameOfChecklistToDelete(name);
    setShowModal(true);
  };

  const handleClickCancel = () => {
    setShowModal(false);
    setIdOfChecklistToDelete("");
    setNameOfChecklistToDelete("");
  };

  const handleCreatedByChange = (event) => {
    setCreatedBy(event.target.value);
  };

  const goToNextPage = () => {
    if (!paginationToken) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    newPrevPaginationTokens.push(currPaginationToken);
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(paginationToken);
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage === 0) return;
    let newPrevPaginationTokens = [...prevPaginationTokens];
    const newCurrPaginationToken = newPrevPaginationTokens.pop();
    const newPaginationToken = currPaginationToken;
    setPrevPaginationTokens(newPrevPaginationTokens);
    setCurrPaginationToken(newCurrPaginationToken);
    setPaginationToken(newPaginationToken);
    setCurrentPage(currentPage - 1);
  };

  const resetPagingState = () => {
    setPaginationToken("");
    setCurrPaginationToken("");
    setPrevPaginationTokens([]);
    setCurrentPage(0);
  };

  const deleteChecklistModal = (
    <Modal open={showModal}>
      <Box sx={modalStyle}>
        <h3>Delete Checklist?</h3>
        <p>Name: {nameOfChecklistToDelete}</p>
        <Button
          variant="outlined"
          size="medium"
          sx={{ "margin-right": "10px" }}
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={handleDeleteChecklist}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );

  const loadingBackdrop = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const searchBar = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginTop="20px"
      paddingX="20px"
      className={classes.searchBar}
    >
      <TextField
        label="Created By"
        variant="outlined"
        size="small"
        onChange={handleCreatedByChange}
      />
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setAlert(true);
        }}
      >
        Search
      </Button>
    </Stack>
  );

  const pageNavigator = (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      marginBottom="20px"
    >
      <IconButton onClick={goToPreviousPage} disabled={currentPage === 0}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className={classes.pageNumber}>
        <div>{currentPage + 1}</div>
      </div>
      <IconButton onClick={goToNextPage} disabled={!paginationToken}>
        <NavigateNextIcon />
      </IconButton>
    </Stack>
  );

  const checklistsTable =
    checklists.length === 0 ? (
      <p>No checklists to show</p>
    ) : (
      <ErrorBoundary>
        <TableContainer component={Paper} className={classes.checklistsTable}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Created</TableCell>
                <TableCell align="right">Created By</TableCell>
                <TableCell align="right">Last Modified</TableCell>
                <TableCell align="right">Last Modified By</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checklists.map((checklist) => (
                <TableRow
                  key={checklist.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">
                    <StyledLink to={`/checklists/${checklist.id}`}>
                      {checklist.name}
                    </StyledLink>
                  </TableCell>
                  <TableCell align="right">
                    {getShortDateString(checklist.createdDate)}
                  </TableCell>
                  <TableCell align="right">{checklist.createdBy}</TableCell>
                  <TableCell align="right">
                    {getShortDateString(checklist.lastModifiedDate)}
                  </TableCell>
                  <TableCell align="right">
                    {checklist.lastModifiedBy}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="danger"
                      onClick={() => {
                        handleClickDelete(checklist.id, checklist.name);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ErrorBoundary>
    );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.backlink}>
            <NavLink to="/admin">
              <ArrowBackIcon />
              <span>Back</span>
            </NavLink>
          </div>
          <h1 className={classes.title}>Checklists</h1>
        </div>
        <p className={classes.title}>Page Total: {count}</p>
        <p className={classes.title}>
          Approximate Total: {approximateTotalCount}
        </p>
        {searchBar}
        {checklistsTable}
        {pageNavigator}
        {showModal && deleteChecklistModal}
      </div>
      {loadingBackdrop}
    </div>
  );
};

export default AdminChecklistsPage;
