import classes from "./InputField.module.css";

const InputField = (props) => {
  const minlength = props.minlength;
  const maxlength = props.maxlength;

  // Handles the keydown event.
  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "Escape") {
      event.target.blur();
    }
  };

  let helperText = "";
  let error = false;
  if (props.value.length < minlength) {
    helperText = `Value must be at least ${minlength} character.`;
    error = true;
  } else if (props.value.length > maxlength) {
    helperText = `Value must be ${maxlength} characters or less.`;
    error = true;
  }

  const classname = error ? classes.error : classes.inputField;

  return (
    <div className={classname}>
      <label htmlFor={props.label}>{props.label}</label>
      <input
        name={props.label}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
      />
      {error && <p className={classes.errorText}>{helperText}</p>}
    </div>
  );
};

export default InputField;
