import AccountDetails from "../../components/AccountDetails/AccountDetails";
import ChangePasswordForm from "../../components/ChangePasswordForm/ChangePasswordForm";
import UserOrders from "../../components/UserOrders/UserOrders";
import Footer from "../../layout/Footer/Footer";
import FeedbackBox from "../../components/FeedbackBox/FeedbackBox";
import classes from "./MyAccount.module.css";

const MyAccount = () => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <AccountDetails className={classes.accountDetails} />
        <ChangePasswordForm />
        <UserOrders />
      </div>
      <FeedbackBox />
      <Footer />
    </div>
  );
};

export default MyAccount;
