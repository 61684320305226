import { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { Auth } from "aws-amplify";
import axios from "axios";

import AuthContext from "../../store/auth-context";
import { showError, showSuccess } from "../../utils/notifications";

// To translate the object key into a nice string (example: searchEngine => Search Engine)
const lookupTable = {
  searchEngine: "Search Engine",
  friendFamily: "Friend/Family",
  fellowPilot: "Fellow Pilot",
  flightSchool: "Flight School",
  podcast: "Podcast",
  reddit: "Reddit",
  instagram: "Instagram",
  facebook: "Facebook",
  youtube: "Youtube",
  twitter: "Twitter",
  news: "News",
};

const HowDidYouHearAboutUsPopup = (props) => {
  const [isSelected, setIsSelected] = useState({
    searchEngine: false,
    friendFamily: false,
    fellowPilot: false,
    flightSchool: false,
    podcast: false,
    reddit: false,
    instagram: false,
    facebook: false,
    youtube: false,
    twitter: false,
    news: false,
    other: false,
  });
  const [otherDescription, setOtherDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      // Make sure user is logged in, but dont show survey to admin.
      if (authCtx.isLoggedIn && !authCtx.isAdmin) {
        // Check if user has answered this survey before (check for existing entry in db)
        try {
          const session = await Auth.currentSession();
          const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/userattributes/exists`;
          const config = {
            headers: {
              Authorization: `Bearer ${session.accessToken.jwtToken}`,
            },
          };
          axios
            .get(url, config)
            .then((res) => {
              // If userattribute doesnt exist, show the user the survey since they havent completed it yet.
              if (!res.data.exists) {
                setIsOpen(true);
              }
            })
            .catch((err) => {
              console.error("Error checking whether userattribute exists", err);
            });
        } catch (err) {
          console.error("Error getting session data: ", err);
        }
      }
    }
    fetchData();
  }, [authCtx]);

  const handleCheckboxChange = (name) => {
    // Get current checkbox selections
    let currentSelections = { ...isSelected };
    // Set specified value to opposite of what it is now, and then set the state.
    currentSelections[name] = !currentSelections[name];
    setIsSelected(currentSelections);
  };

  const handleOtherDescriptionChange = (event) => {
    setOtherDescription(event.target.value);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const resetForm = () => {
    setIsSelected({
      searchEngine: false,
      friendFamily: false,
      fellowPilot: false,
      flightSchool: false,
      podcast: false,
      reddit: false,
      instagram: false,
      facebook: false,
      youtube: false,
      twitter: false,
      news: false,
      other: false,
    });
    setOtherDescription("");
  };

  // Determine if form has been filled out correctly.
  const validateForm = () => {
    // If "Other" is selected, make sure that the text box has also been filled.
    if (isSelected.other) {
      // Description must be between 1-500 characters.
      if (otherDescription.length < 1 || otherDescription.length > 500)
        return false;
      else return true;
    }

    // Other not selected, so check that at least one item has been selected.
    const selectedOptions = Object.keys(isSelected);
    for (let i = 0; i < selectedOptions.length; i++) {
      const currentOption = selectedOptions[i];
      if (isSelected[currentOption]) {
        return true;
      }
    }
    return false;
  };

  // Handles when the survey is submitted (send button clicked).
  const handleSubmit = async (event) => {
    // Validate form.
    if (!validateForm()) {
      showError(
        "Error",
        "Please select at least one item, and keep text between 1-500 characters."
      );
      return;
    }
    // Map form state to values we can send to API.
    let howDidYouHearAboutUs = [];
    const selectedOptions = Object.keys(isSelected);
    for (let i = 0; i < selectedOptions.length; i++) {
      const currentOption = selectedOptions[i];
      if (isSelected[currentOption]) {
        if (currentOption === "other") {
          howDidYouHearAboutUs.push({
            type: "other",
            description: otherDescription,
          });
        } else {
          howDidYouHearAboutUs.push({
            type: "existing",
            option: lookupTable[currentOption],
          });
        }
      }
    }
    // Get authToken and email and send post request.
    try {
      setIsLoading(true);
      const session = await Auth.currentSession();
      const url = `${process.env.REACT_APP_CHECKLISTS_SERVICE_BASE_URL}/userattributes`;
      const config = {
        headers: {
          Authorization: `Bearer ${session.accessToken.jwtToken}`,
        },
      };
      const body = {
        email: session.idToken.payload.email,
        howDidYouHearAboutUs,
      };
      axios
        .post(url, body, config)
        .then((res) => {
          setIsLoading(false);
          showSuccess("Thank You!", "We appreciate your help.");
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(`Error creating UserAttribute entry: `, err);
        });
    } catch (err) {
      setIsLoading(false);
      console.error(`Error getting current session: `, err);
    }
    resetForm();
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>How Did You Hear About Us?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "red" }}>
          Select all that apply
        </DialogContentText>
        <Divider sx={{ marginBottom: "10px" }} />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.searchEngine}
                onChange={() => {
                  handleCheckboxChange("searchEngine");
                }}
              />
            }
            label="Search Engine (Google, Bing, etc)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.friendFamily}
                onChange={() => {
                  handleCheckboxChange("friendFamily");
                }}
              />
            }
            label="Friend/Family"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.fellowPilot}
                onChange={() => {
                  handleCheckboxChange("fellowPilot");
                }}
              />
            }
            label="Fellow Pilot"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.flightSchool}
                onChange={() => {
                  handleCheckboxChange("flightSchool");
                }}
              />
            }
            label="Flight School"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.podcast}
                onChange={() => {
                  handleCheckboxChange("podcast");
                }}
              />
            }
            label="Podcast"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.reddit}
                onChange={() => {
                  handleCheckboxChange("reddit");
                }}
              />
            }
            label="Reddit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.instagram}
                onChange={() => {
                  handleCheckboxChange("instagram");
                }}
              />
            }
            label="Instagram"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.facebook}
                onChange={() => {
                  handleCheckboxChange("facebook");
                }}
              />
            }
            label="Facebook"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.youtube}
                onChange={() => {
                  handleCheckboxChange("youtube");
                }}
              />
            }
            label="Youtube"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.twitter}
                onChange={() => {
                  handleCheckboxChange("twitter");
                }}
              />
            }
            label="Twitter (X)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.news}
                onChange={() => {
                  handleCheckboxChange("news");
                }}
              />
            }
            label="News"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected.other}
                onChange={() => {
                  handleCheckboxChange("other");
                }}
              />
            }
            label="Other"
          />
          {isSelected.other && (
            <TextField
              placeholder="Describe how you heard about us."
              value={otherDescription}
              onChange={handleOtherDescriptionChange}
            />
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default HowDidYouHearAboutUsPopup;
