const amplify_config = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
};
const amplify_config_TEST = {
  Auth: {
    region: process.env.REACT_APP_TEST_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_TEST_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_TEST_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
};

export default process.env.REACT_APP_NODE_ENV === "test"
  ? amplify_config_TEST
  : amplify_config;
