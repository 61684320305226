import { LoadingButton } from "@mui/lab";
import {
  Radio,
  Modal,
  Box,
  Stack,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import ReactCountryFlag from "react-country-flag";

import classes from "./SubscriptionPage.module.css";
import AuthContext from "../../store/auth-context";
import SubscriptionTimeline from "../../components/SubscriptionTimeline/SubscriptionTimeline";
import { showError } from "../../utils/notifications";
import { handleAuthError, handleAxiosError } from "../../utils/errorHandlers";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import pricing from "../../config/pricing.json";

// Constants
const HANGAR_PLAN_LOOKUP_KEY_CAD = pricing.subscriptions.hangar.cad.lookup_key;
const FLEET_PLAN_LOOKUP_KEY_CAD = pricing.subscriptions.fleet.cad.lookup_key;

const HANGAR_PLAN_LOOKUP_KEY_USD = pricing.subscriptions.hangar.usd.lookup_key;
const FLEET_PLAN_LOOKUP_KEY_USD = pricing.subscriptions.fleet.usd.lookup_key;

const HANGAR_PLAN_CAD_PRICE = pricing.subscriptions.hangar.cad.price;
const FLEET_PLAN_CAD_PRICE = pricing.subscriptions.fleet.cad.price;

const HANGAR_PLAN_USD_PRICE = pricing.subscriptions.hangar.usd.price;
const FLEET_PLAN_USD_PRICE = pricing.subscriptions.fleet.usd.price;

// Style for modal (should probably move to a separate file later)
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SubscriptionPage = () => {
  const [currentPlan, setCurrentPlan] = useState({});
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState("");
  const [isSendingUpdate, setIsSendingUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState("CAD");
  const [customerCurrency, setCustomerCurrency] = useState("");

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const noCurrentSubscription = currentPlan.name === "free_plan";

  const showHangarPlanNote =
    currentPlan.name === HANGAR_PLAN_LOOKUP_KEY_CAD ||
    currentPlan.name === HANGAR_PLAN_LOOKUP_KEY_USD
      ? true
      : false;

  const showFleetPlanNote =
    currentPlan.name === FLEET_PLAN_LOOKUP_KEY_CAD ||
    currentPlan.name === FLEET_PLAN_LOOKUP_KEY_USD
      ? true
      : false;

  const hangarSelected =
    currentSelectedPlan === HANGAR_PLAN_LOOKUP_KEY_CAD ||
    currentSelectedPlan === HANGAR_PLAN_LOOKUP_KEY_USD
      ? true
      : false;

  const fleetSelected =
    currentSelectedPlan === FLEET_PLAN_LOOKUP_KEY_CAD ||
    currentSelectedPlan === FLEET_PLAN_LOOKUP_KEY_USD
      ? true
      : false;

  const buttonDisabled =
    currentSelectedPlan === "" ||
    currentPlan.name === currentSelectedPlan ||
    isSendingUpdate ||
    noCurrentSubscription;

  // Set price base on currency selected
  const hangar_plan_price =
    currency === "CAD" ? HANGAR_PLAN_CAD_PRICE : HANGAR_PLAN_USD_PRICE;
  const fleet_plan_price =
    currency === "CAD" ? FLEET_PLAN_CAD_PRICE : FLEET_PLAN_USD_PRICE;

  useEffect(() => {
    Auth.currentSession()
      .then((res) => {
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/subscriptions/info`;
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .get(url, config)
          .then((data) => {
            if (data.data.error) {
              console.error(data.data.error);
              // showError("Error", data.data.error);
              return;
            }
            setCurrentPlan(data.data);
            setCurrentSelectedPlan(data.data.name);

            // Set currency based on customers current subscription.
            const customerCurrency = data.data.currency.toUpperCase();
            setCurrency(customerCurrency);
            setCustomerCurrency(customerCurrency);
          })
          .catch((err) => {
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  }, [authCtx, navigate]);

  const handleClickSubscriptionBox = (planName) => {
    setCurrentSelectedPlan(planName);
  };

  const handleUpdateSubscription = (event) => {
    event.preventDefault();

    // If user doesnt have a plan, show error and redirect to pricing page.
    if (noCurrentSubscription) {
      showError(
        "Error",
        "Cannot update your subscription because you don't have any subscription to update. Purchase one from the Pricing page."
      );
      navigate("/pricing");
      return;
    }

    if (customerCurrency.length !== 0 && customerCurrency !== currency) {
      showError(
        "Error",
        `Your current subscription is in ${customerCurrency} so you cannot change to a ${currency} plan.`
      );
      setShowModal(false);
      return;
    }
    Auth.currentSession()
      .then((res) => {
        setIsSendingUpdate(true);
        const url = `${process.env.REACT_APP_PAYMENTS_SERVICE_BASE_URL}/subscriptions`;
        const body = {
          name: currentSelectedPlan,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${res.accessToken.jwtToken}`,
          },
        };
        axios
          .put(url, body, config)
          .then((res) => {
            if (
              typeof res.body !== "undefined" &&
              typeof res.body.error !== "undefined"
            ) {
              showError("Error", res.data.error);
              setIsSendingUpdate(false);
              setShowModal(false);
            } else {
              // Update was successful
              setIsSendingUpdate(false);
              navigate("/success");
            }
          })
          .catch((err) => {
            setIsSendingUpdate(false);
            handleAxiosError(err);
          });
      })
      .catch((err) => {
        handleAuthError(err, navigate, authCtx);
      });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    // Change currently selected plan to new currency (wont have changed yet since it changes on selection)
    if (currentSelectedPlan.length !== 0) {
      const pieces = currentSelectedPlan.split("_");
      const currency = pieces[2];
      const newCurrency = currency === "cad" ? "usd" : "cad";
      const newPlan = `${pieces[0]}_${pieces[1]}_${newCurrency}`;
      setCurrentSelectedPlan(newPlan);
    }
  };

  const getReadablePlanName = (lookup_key) => {
    if (lookup_key.length === 0 || lookup_key === "free_plan") {
      return;
    }
    const items = lookup_key.split("_");
    const plan = items[0][0].toUpperCase() + items[0].slice(1);
    const currency = items[2].toUpperCase();
    return `${plan} Plan (${currency})`;
  };

  const confirmModal = (
    <Modal open={showModal}>
      <Box sx={style}>
        <h3>Change Subscription</h3>
        <p>
          Change subscription to {getReadablePlanName(currentSelectedPlan)}?
        </p>
        <form>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="neutral"
              size="medium"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="neutral"
              size="medium"
              onClick={handleUpdateSubscription}
              loading={isSendingUpdate}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Modal>
  );

  const noCurrentSubscriptionNote = (
    <p className={classes.noCurrentSubscriptionNote}>
      You do not have a subscription. Go to the Pricing page to purchase one.
      This page is to update existing subscriptions.
    </p>
  );

  return (
    <div className={classes.container}>
      <div className={classes.inset}>
        <div className={classes.manager}>
          <p className={classes.title}>Subscription Manager</p>
          <div className={classes.currencySelector}>
            <FormControl>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                name="currency"
                label="Currency"
                value={currency}
                defaultValue="CAD"
                onChange={handleCurrencyChange}
              >
                <MenuItem value="CAD">
                  {<ReactCountryFlag countryCode="CA" />} CAD
                </MenuItem>
                <MenuItem value="USD">
                  {<ReactCountryFlag countryCode="US" />} USD
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {noCurrentSubscription && noCurrentSubscriptionNote}
          <div
            className={classes.subscriptionBox}
            onClick={() => {
              if (currency === "CAD") {
                handleClickSubscriptionBox(HANGAR_PLAN_LOOKUP_KEY_CAD);
              } else if (currency === "USD") {
                handleClickSubscriptionBox(HANGAR_PLAN_LOOKUP_KEY_USD);
              }
            }}
          >
            <span>
              <Radio checked={hangarSelected} />
              <p>
                Hangar Plan{" "}
                <span className={classes.planPrice}>
                  (${hangar_plan_price}/month)
                </span>
              </p>
            </span>
            {showHangarPlanNote && (
              <span className={classes.currentSubscriptionNote}>
                Current plan
              </span>
            )}
          </div>
          <div
            className={classes.subscriptionBox}
            onClick={() => {
              if (currency === "CAD") {
                handleClickSubscriptionBox(FLEET_PLAN_LOOKUP_KEY_CAD);
              } else if (currency === "USD") {
                handleClickSubscriptionBox(FLEET_PLAN_LOOKUP_KEY_USD);
              }
            }}
          >
            <span>
              <Radio checked={fleetSelected} />
              <p>
                Fleet Plan{" "}
                <span className={classes.planPrice}>
                  (${fleet_plan_price}/month)
                </span>
              </p>
            </span>
            {showFleetPlanNote && (
              <span className={classes.currentSubscriptionNote}>
                Your current plan
              </span>
            )}
          </div>
          <div className={classes.controls}>
            <Button
              variant="contained"
              onClick={handleOpenModal}
              disabled={buttonDisabled}
            >
              Purchase this plan
            </Button>
          </div>
          <div className={classes.noteContainer}>
            <p className={classes.note}>
              Subscription upgrades will be processed immediately and your costs
              will be prorated. Subscription downgrades will occur at the end of
              the current period.
            </p>
          </div>
        </div>

        <div className={classes.timeline}>
          <ErrorBoundary>
            <SubscriptionTimeline />
          </ErrorBoundary>
        </div>
      </div>
      {confirmModal}
    </div>
  );
};

export default SubscriptionPage;
